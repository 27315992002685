import { IAgent } from './../../../store/modules/agent/agent.models';

import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { store, storeTypes } from '@/app/store';
import {
  BusinessStatus,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import { Subscription, fromEvent } from 'rxjs';
import moment from 'moment';
import $ from 'jquery';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { IUser } from '@/app/store/modules/user/user.models';
import eventBus from '@/main';
import CheckBox from '../../shared/check-box/CheckBox.vue';

interface FiltersConfig {
  label: string;
  value: string;
  icon: string;
  config: [
    {
      name_filter: string;
      type_filter: string;
      source: string;
      selectedOption: any;
    },
  ];
}
@Component({
  name: 'conversations-category-list',
  components: {
    PerfectScrollbar,
    CheckBox,
  },
})
export default class ConversationsCategoryList extends VueWizard {
  @Prop() public readonly conversations!: Conversation[];
  @Prop() public readonly categoryIdentification!: string;
  @Prop() public readonly parentAccordion!: string;
  @Prop() public readonly categoryTitle!: string;

  @Prop() public readonly businessStatuses!: BusinessStatus[];
  @Prop() public readonly showCollapse!: boolean;
  @Prop() public readonly currentList!: number;
  @Prop() public readonly listNumber!: number;
  @Prop() public readonly noFilteredConversations!: Conversation[];
  @Prop() public readonly activeFilters!: string[];
  @Prop() public readonly filterBy!: string;
  @Prop() public readonly filterValue!: Conversation[];
  @Prop() public readonly loadingFilters!: boolean;
  @Prop() public readonly loading = false;
  @Prop() public readonly showCheckboxes!: boolean;

  public userLoged: IUser | null = store.state.user!.user;
  public agentLogued: IAgent | null = store.state.agent!.agent;
  public collapseElement: JQuery<Element> | null = null;
  public collapseShowSubscription: Subscription | null = null;
  public currentPage = 1; // lo inicializo enb uno porque por defecto en el login carga la pagina cero
  public loadingMore = false;
  public checkedConversations: {
    _id: string;
    checked: boolean;
  }[] = [];
  public selectedConversations: string[] = [];

  get sizeOpenConversation(): number {
    return store.state.agent!.agentOpenConversations.length;
  }

  get sizeCloseConversation(): number {
    return store.state.agent!.agentCloseConversations.length;
  }

  get sizeQueueConversation(): number {
    return store.state.agent!.agentQueue.length;
  }

  get sizeAutoConversation(): number {
    return store.state.agent!.agentAutoConversations.length;
  }

  get currentTab(): string {
    return store.state.agent!.tabSelected;
  }

  get loadingQueue(): boolean {
    return store.state.agent!.loadingQueue;
  }
  get currentFilter(): FiltersConfig {
    const filterQueue = store.state.agent!.filterSelectedQueue;
    const filterOpen = store.state.agent!.filterSelectedOpen;
    const filterClose = store.state.agent!.filterSelectedClose;
    const filterAuto = store.state.agent!.filterSelectedAuto;
    switch (this.currentTab) {
      case 'encola':
        return filterQueue;
      case 'activas':
        return filterOpen;
      case 'resueltas':
        return filterClose;
      case 'auto':
        return filterAuto;
      default:
        return {
          label: 'Todos',
          value: 'all',
          icon: 'mdi-filter-outline',
          config: [
            {
              name_filter: 'Todos',
              type_filter: 'all',
              source: 'mdi-filter-outline',
              selectedOption: [],
            },
          ],
        };
    }
  }

  @Watch('sizeQueueConversation')
  onSizeQueueConversationChange(): void {
    if (
      this.sizeQueueConversation < 20 &&
      this.currentTab === 'encola' &&
      !this.loadingQueue
    ) {
      this.loadMore();
    }
  }

  @Watch('currentTab')
  onCurrentTabChange(): void {
    this.conversations.forEach((conversation: Conversation) => {
      conversation.checked = false;
    });
    this.selectedConversations = [];
    eventBus.$emit('selectedConversations', {
      conversations: this.selectedConversations,
      tam: this.selectedConversations.length,
    });
  }

  @Watch('conversations')
  onConversationsChange(): void {
    // check if the conversation is in conversations array
    // if not, remove it from selectedConversations array
    this.selectedConversations = this.selectedConversations.filter(
      conversation =>
        this.conversations.find(
          (conversationItem: Conversation) =>
            conversationItem._id === conversation,
        ),
    );
  }

  mounted(): void {
    this.collapseElement = $(
      `#${this.categoryIdentification}-conversations-collapse`,
    );
    this.collapseShowSubscription = fromEvent(
      this.collapseElement,
      'show.bs.collapse',
    ).subscribe(() => this.$emit('openCollapse', this.listNumber));
    this.onCurrentListChange();
    const scrollqueue = document.getElementById('queueinfinit-scroll');
    if (scrollqueue) {
      scrollqueue.addEventListener('scroll', this.handleScroll);
    }
    // const scrollactive = document.getElementById("activeinfinit-scroll");
    // if (scrollactive) {
    //   scrollactive.addEventListener("scroll", this.handleScroll);
    // }
    const scrollclosed = document.getElementById('closedinfinit-scroll');
    if (scrollclosed) {
      scrollclosed.addEventListener('scroll', this.handleScroll);
    }
  }

  @Watch('currentList')
  onCurrentListChange(): void {
    if (this.collapseElement) {
      if (this.currentList === this.listNumber) {
        this.collapseElement.collapse('show');
      }
    }
  }

  onSelect(conversation: Conversation): void {
    this.$emit('selectConversation', conversation);
  }

  geInitials(conversation: Conversation): string {
    let userName = 'C';
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME &&
      conversation.variables.CONSUMER_NAME.value != undefined &&
      isNaN(conversation.variables.CONSUMER_NAME.value)
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
      if (userName != undefined && userName != '' && userName != null) {
        userName = userName.charAt(0);
      }
    }
    return userName;
  }

  getUserPicture(conversation: Conversation): string {
    let profileUrl = '';

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE.value != undefined
    ) {
      profileUrl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
    }
    return profileUrl;
  }

  getConsumer(conversation: Conversation): string {
    let consumer = conversation.currentConsumer;
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }

  getUserName(conversation: Conversation): string {
    let userName = '';
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }

  getLastText(item: Conversation): string {
    let lastText = '';

    if (item.conversationJson == undefined) return lastText;

    if (item.conversationJson.text != undefined) {
      lastText = item.conversationJson.text.body;
    }
    if (item.conversationJson.button != undefined) {
      lastText = item.conversationJson.button.text;
    }
    if (item.conversationJson.sticker != undefined) {
      lastText = 'Sticker';
    }
    if (item.conversationJson!.audio != undefined) {
      lastText = 'Audio';
    }
    if (item.conversationJson!.type === 'image') {
      lastText = 'Imagen';
    }
    if (item.conversationJson!.document != undefined) {
      lastText = item.conversationJson!.document.filename;
    }

    return lastText;
  }

  cleanTopic(topic: string): string {
    let topicElements: any[] = [];
    if (topic != undefined && topic.includes('_')) {
      topicElements = topic.split('_');
      topic = '';
      for (let i = 2; i < topicElements.length; i++) {
        topic = topic + topicElements[i] + ' ';
      }
      topic.trim();
    }
    return topic;
  }

  getTopic(conversation: Conversation): string {
    return this.cleanTopic(conversation.currentTopic);
  }

  formatUpdatedDate(date: Date | string): string {
    moment.locale('es');
    const today = new Date();
    const toFormatDate = new Date(date.toString());
    if (today.getFullYear() === toFormatDate.getFullYear()) {
      // if is today
      if (
        today.getDate() === toFormatDate.getDate() &&
        today.getMonth() === toFormatDate.getMonth()
      ) {
        return moment(date).format('HH:mm a');
      }

      return moment(date).format('DD MMM');
    }
    return moment(date).format('DD MMM YYYY');
  }

  statusColorStyle(status: BusinessStatus): any {
    if (status) {
      const style = {
        'border-left': `10px solid ${status.color}`,
      };
      return style;
    } else {
      return {};
    }
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  handleScroll(e: any): void {
    const scroll = e.target;

    if (
      scroll.scrollTop + scroll.clientHeight + 1 >= scroll.scrollHeight &&
      !this.loadingMore
    ) {
      this.loadingMore = true;
      this.loadMore();
    }
  }

  getBusinessStatus(status: string): BusinessStatus {
    const statusColor = this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status,
    );
    return statusColor!;
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  get alertNewInteractions(): boolean {
    let newInteractions = 0;
    this.noFilteredConversations.forEach((conversation: Conversation) => {
      if (conversation.newinteractions) {
        newInteractions += conversation.newinteractions;
      }
    });
    return newInteractions > 0;
  }

  /***
   * paginacion
   */
  async loadMore(): Promise<void> {
    if (this.categoryIdentification == 'queue') {
      const queue = store.state.agent!.orderQueue;
      const currentPage = store.state.agent!.pageQueue;

      await store.dispatch(
        storeTypes.agent.actions.loadAgentQueues({
          subscriptions: store.state.agent!.agent.subscriptions,
          page: currentPage,
          sort: -queue,
        }),
      );
      this.loadingMore = false;
    }
    if (this.categoryIdentification == 'active') {
      const active = store.state.agent!.orderOpen;
      const currentPage = store.state.agent!.pageOpen;
      await store.dispatch(
        storeTypes.agent.actions.loadAgentOpenConversations({
          agent_id: store.state.agent!.agent._id,
          page: currentPage,
          sort: active,
        }),
      );
      this.loadingMore = false;
    }
    if (this.categoryIdentification == 'resolved') {
      const close = store.state.agent!.orderClose;
      const currentPage = store.state.agent!.pageClose;
      await store.dispatch(
        storeTypes.agent.actions.loadAgentCloseConversations({
          agent_id: store.state.agent!.agent._id,
          page: currentPage,
          sort: close,
        }),
      );
      this.loadingMore = false;
    }

    if (this.categoryIdentification == 'conversation') {
      const auto = store.state.agent!.orderAuto;
      const currentPage = store.state.agent!.pageAuto;
      await store.dispatch(
        storeTypes.agent.actions.loadAgentAutoConversations({
          subscriptions: store.state.agent!.agent.subscriptions,
          page: currentPage,
          sort: auto,
        }),
      );
      this.loadingMore = false;
    }
  }

  created(): void {
    eventBus.$on('changepage', () => {
      this.currentPage = 1;
    });
    eventBus.$on('selectedAll', (data: any) => {
      this.selectedConversations = data.conversations;
    });
  }

  onChange(e: any) {
    // push the selected conversation to the selectedConversations array
    // agregar la conversacion seleccionada al array selectedConversations
    // si ya esta en el array, se elimina
    if (this.selectedConversations.includes(e.target.value)) {
      this.selectedConversations = this.selectedConversations.filter(
        conversation => conversation !== e.target.value,
      );
    } else this.selectedConversations.push(e.target.value);

    // emit the selected conversations array to the parent component
    eventBus.$emit('selectedConversations', {
      conversations: this.selectedConversations,
      tam: this.selectedConversations.length,
    });
  }
}
