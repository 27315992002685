import { render, staticRenderFns } from "./SearchConversationWidget.vue?vue&type=template&id=4f5872b3&scoped=true"
import script from "./search-conversation-widget.ts?vue&type=script&lang=ts&external"
export * from "./search-conversation-widget.ts?vue&type=script&lang=ts&external"
import style0 from "./SearchConversationWidget.vue?vue&type=style&index=0&id=4f5872b3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5872b3",
  null
  
)

export default component.exports