import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { store } from '@/app/store';
import {
  BusinessStatus,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import { Subscription, fromEvent } from 'rxjs';

import moment from 'moment';
import $ from 'jquery';
import Vue from 'vue';

import eventBus from '@/main';
@Component({ name: 'conversations-category-tabs' })
export default class ConversationsCategoryTabs extends VueWizard {
  @Prop() public readonly conversations!: Conversation[];
  @Prop() public readonly categoryIdentification!: string;
  @Prop() public readonly currentTab!: string;
  @Prop() public readonly parentAccordion!: string;
  @Prop() public readonly categoryTitle!: string;
  @Prop() public readonly businessStatuses!: BusinessStatus[];
  @Prop() public readonly showCollapse!: boolean;
  @Prop() public readonly currentList!: number;
  @Prop() public readonly listNumber!: number;
  @Prop() public readonly noFilteredConversations!: Conversation[];
  @Prop() public readonly activeFilters!: string[];

  public collapseElement: JQuery<Element> | null = null;
  public collapseShowSubscription: Subscription | null = null;
  public EventBus: Vue = new Vue();

  get loading(): any {
    return store.state.user!.loading;
  }

  mounted(): void {
    this.collapseElement = $(
      `#${this.categoryIdentification}-conversations-collapse`,
    );
    this.collapseShowSubscription = fromEvent(
      this.collapseElement,
      'show.bs.collapse',
    ).subscribe(() => this.$emit('openCollapse', this.listNumber));
    this.onCurrentListChange();
  }

  beforeDestroy(): void {
    if (this.collapseShowSubscription) {
      this.collapseShowSubscription.unsubscribe();
    }
  }

  @Watch('currentList')
  onCurrentListChange(): void {
    if (this.collapseElement) {
      if (this.currentList === this.listNumber) {
        this.collapseElement.collapse('show');
      }
    }
  }

  onSelect(conversation: Conversation): void {
    this.$emit('selectConversation', conversation);
  }

  getUserPicture(conversation: Conversation): string {
    let profileUrl = '';

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE.value != undefined
    ) {
      profileUrl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
    }
    return profileUrl;
  }

  getConsumer(conversation: Conversation): string {
    let consumer = conversation.currentConsumer;
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }

  getUserName(conversation: Conversation): string {
    let userName = '';
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }

  cleanTopic(topic: string): string {
    let topicElements: any[] = [];
    if (topic != undefined && topic.includes('_')) {
      topicElements = topic.split('_');
      topic = '';
      for (let i = 2; i < topicElements.length; i++) {
        topic = topic + topicElements[i] + ' ';
      }
      topic.trim();
    }
    return topic;
  }

  getTopic(conversation: Conversation): string {
    return this.cleanTopic(conversation.currentTopic);
  }

  formatUpdatedDate(date: Date | string): string {
    moment.locale('es');
    const today = new Date();
    const toFormatDate = new Date(date.toString());
    if (today.getFullYear() === toFormatDate.getFullYear()) {
      if (
        today.getDay() === toFormatDate.getDay() &&
        toFormatDate.getMonth() === today.getMonth()
      ) {
        return moment(date).format('hh:mm a');
      } else {
        return moment(date).format('DD MMM');
      }
    } else {
      return moment(date).format('DD/MM/YYYY');
    }
  }

  statusColorStyle(status: BusinessStatus): any {
    if (status) {
      return {
        '--label-bg-color-r': this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).r
          : 0,
        '--label-bg-color-g': this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).g
          : 0,
        '--label-bg-color-b': this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).b
          : 0,
      };
    } else {
      return {};
    }
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  getBusinessStatus(status: string): BusinessStatus | undefined {
    return this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status,
    );
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  get alertNewInteractions(): boolean {
    let newInteractions = 0;
    this.noFilteredConversations.forEach((conversation: Conversation) => {
      if (conversation.newinteractions) {
        newInteractions += conversation.newinteractions;
      }
    });
    return newInteractions > 0;
  }

  //nota mental: el event bus que uso aqui es el general que defini en el main .ts ya que permite comunicacion entre todos los componentes
  changeTab(categoryIdentification: any) {
    this.$emit('change', categoryIdentification);
    eventBus.$emit('changepage');
  }

  get total(): number {
    let total = 0;
    if (this.categoryIdentification == 'encola') {
      if (store.state.agent!.filterSelectedQueue.value !== 'all') {
        total = store.state.agent!.agentQueueTotalFiltered;
      } else {
        total = store.state.agent!.agentQueueTotal
          ? store.state.agent!.agentQueueTotal
          : 0;
      }
    }

    if (this.categoryIdentification == 'activas') {
      if (store.state.agent!.filterSelectedOpen.value !== 'all') {
        total = store.state.agent!.agentOpenTotalConversationsFiltered
          ? store.state.agent!.agentOpenTotalConversationsFiltered
          : 0;
      } else {
        const conversationsOpen = store.state.agent!.agentOpenConversations;
        let conversationsOpenTotal = 0;
        conversationsOpen.forEach((conversation: Conversation) => {
          if (conversation.newinteractions > 0) {
            conversationsOpenTotal++;
          }
        });

        total = conversationsOpenTotal;
      }
    }
    if (this.categoryIdentification == 'resueltas') {
      if (store.state.agent!.filterSelectedClose.value !== 'all') {
        total = store.state.agent!.agentCloseTotalConversationsFiltered
          ? store.state.agent!.agentCloseTotalConversationsFiltered
          : 0;
      } else {
        total = store.state.agent!.agentCloseTotalConversations
          ? store.state.agent!.agentCloseTotalConversations
          : 0;
      }
    }

    return total;
  }
}
