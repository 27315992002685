import { Component } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import AgentSidebar from '@/app/views/agent-sidebar/agent-sidebar.vue';
@Component({
  name: 'waiting',
  components: { AgentSidebar },
})
export default class Waiting extends VueWizard {
  // Mensajes de cargas
  public messages: string[] = [
    'Poniendo en marcha el motor...',
    'Cargando mensajes...',
    'Cargando usuarios...',
    'Cargando configuraciones...',
  ];
  public message: string = this.messages[0];
  /**
   * Metodo encargado de rotar los mensajes cada 2 segundos para dar la sensacion de carga
   * empezando por el primer mensaje del array, terminando en el ultimo y volviendo a empezar
   */
  public rotateMessages() {
    let index = 0;
    setInterval(() => {
      this.message = this.messages[index];
      index++;
      if (index >= this.messages.length) {
        index = 0;
      }
    }, 2000);
  }

  async mounted() {
    this.rotateMessages();
    // const userLogin = this.$store.state.user.user.login;
    // if (userLogin) {
    //   await new Promise((resolve) => setTimeout(resolve, 2000));
    //   this.$router.push({ name: "dashboard" });
    // } else {
    //   await new Promise((resolve) => setTimeout(resolve, 2000));
    //   this.$router.push({ name: "login" });
    // }
  }
}
