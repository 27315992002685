import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR, COUNTRIES } from '@/config';
import { store } from '@/app/store';
import {
  ContactData,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import moment from 'moment';
import axios from 'axios';
const _ = require('lodash');

@Component({
  name: 'modal-llamadas',
})
export default class ModalLlamadas extends VueWizard {
  @Prop() readonly modalVisible!: boolean;
  @Prop() public dropiIncidenceStatus: any;
  @Prop() public project: any;
  @Prop() EventBus!: Vue;

  public styles = {
    top: '0',
    left: '0',
  };

  public orders: any[] = [];
  public state_collapsed: boolean = false;
  public disableButton: boolean = false;
  public tokenDropiError: boolean = false;
  public loadingOrders: boolean = false;
  public contactData: ContactData = {};
  public arrayContactData: any[] = [];

  // Iris variables
  public irisUrl: string = 'https://irispbxcloud.com/';
  public generarLlamada: string = 'manager/api/public/llamadas/generar';

  handleOk() {
    this.EventBus.$emit('llamada', false);
  }
  handleCancel() {
    this.EventBus.$emit('llamada', false);
  }

  async mounted() {
    await this.getOrders();
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }
  getUserPicture(item: any): any {
    let profileurl = '';

    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }

    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      profileurl =
        'https://ui-avatars.com/api/?name=' +
        conversation.variables.CONSUMER_NAME.value +
        '&size=64&background=e0f5ff&color=007ab4&font-size=0.25&bold=true';
    }

    return profileurl;
  }
  getUserName(item: any): any {
    let userName = '';
    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }
  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.$notification.success({
      placement: 'bottomRight',
      message: `Texto copiado`,
      description: `El texto se ha copiado al portapapeles`,
    });
  }
  toggleRightSidebar() {
    const el = this.$refs.rightSidebarRef as HTMLElement;
    if (el.classList.contains('hide-right-sidebar-tablet')) {
      el.classList.toggle('hide-right-sidebar');
    } else {
      el.classList.remove('hide-right-sidebar-tablet');
    }
  }

  percentageReturnedOrders(): number {
    if (this.orders.length > 0) {
      const order = this.orders[0];
      const client_total_orders_returneds = order.client_total_orders_returneds;
      const client_total_orders = order.client_total_orders;

      if (client_total_orders === 0) return 0;

      const returnedPercentage =
        (client_total_orders_returneds / client_total_orders) * 100;
      const roundedPercentage = Math.floor(returnedPercentage);
      return roundedPercentage;
    } else {
      return 0;
    }
  }

  orderActivityAlertClass(): string {
    const percentage = this.percentageReturnedOrders();
    if (percentage === 0) {
      return 'order-activity-alert--green';
    } else if (percentage > 0 && percentage < 20) {
      return 'order-activity-alert--yellow';
    } else if (percentage >= 20 && percentage < 50) {
      return 'order-activity-alert--orange';
    } else if (percentage >= 50 && percentage <= 100) {
      return 'order-activity-alert--red';
    } else {
      return 'order-activity-alert--green';
    }
  }

  expandAllOrders(): void {
    const el = this.$refs.orders_collapse_group as any;

    // if all elements are collapsed, expand them all
    // otherwise, collapse them all
    if (el.elements.every((e: any) => !e.status)) {
      el.openAll();
      this.state_collapsed = false;
    } else {
      el.closeAll();
      this.state_collapsed = true;
    }
  }
  updateCollapseStatus(obj: any): void {
    if (!obj.status === true) {
      obj.vm.$el.classList.add('collapsed');
    } else {
      obj.vm.$el.classList.remove('collapsed');
    }
  }

  formatUpdatedDate(date: Date | string, showTime: Boolean): string {
    moment.locale('es');
    if (showTime) {
      return moment(date).format('DD/MM/YYYY - hh:mm');
    }
    return moment(date).format('DD/MM/YYYY');
  }

  fotmatNumber(value: string): string {
    const num = parseFloat(value);
    const formatted =
      '$' +
      num
        .toLocaleString('es-CO', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(',', '.');
    return formatted;
  }

  getAreaCode(country: string): number {
    switch (country) {
      case 'CO':
        return 57;
      case 'MX':
        return 52;
      case 'PA':
        return 507;
      case 'EC':
        return 593;
      case 'PE':
        return 51;
      case 'CL':
        return 56;
      default:
        return 57;
    }
  }

  getDropiUrl(country: string): string {
    switch (country) {
      case 'CO':
        return COUNTRIES.CO;
      case 'MX':
        return COUNTRIES.MX;
      case 'PA':
        return COUNTRIES.PA;
      case 'EC':
        return COUNTRIES.EC;
      case 'PE':
        return COUNTRIES.PE;
      case 'CL':
        return COUNTRIES.CL;
      case 'ES':
        return COUNTRIES.ES;
      case 'PY':
        return COUNTRIES.PY;
      default:
        return COUNTRIES.CO;
    }
  }
  getNewConsumer(consumer: string, areaCode: number): string {
    const tamanio = String(areaCode);
    const newConsumer = consumer.substring(tamanio.length);

    if (newConsumer && newConsumer !== '') {
      return newConsumer;
    }

    return '';
  }

  async getOrders(): Promise<void> {
    try {
      this.loadingOrders = true;
      this.orders = [];
      const requests = (this.project!.dropiUsers || []).map(
        async (dropiUser: any) => {
          // if country is not defined, we use the default country of the project
          let { country, token, name } = dropiUser;
          if (!country) {
            country = this.project!.country;
          }
          const areaCode = this.getAreaCode(country);
          const dropi_url = this.getDropiUrl(country);
          const consumer = this.currentConversation.currentConsumer;

          const newConsumer = this.getNewConsumer(consumer, areaCode);
          if (newConsumer) {
            const url = `${dropi_url}/orders/myorders?filter_by=CELULAR&value_filter_by=${newConsumer}&orderBy=id&orderDirection=desc&start=0`;
            const requestOptions = {
              method: 'GET',
              headers: {
                'dropi-integration-key': token,
              },
            };
            const response = await fetch(url, requestOptions);
            if (!response.ok) {
              throw new Error(response.status + ' - Error de obtener órdenes');
            }
            const data = await response.json();
            return { objects: data.objects, country, token, name };
          }

          return [];
        },
      );

      const responses = await Promise.all(requests);
      responses.forEach((response: any) => {
        if (response.objects) {
          response.objects.forEach((order: any) => {
            this.orders.push({
              ...order,
              shop_country: response.country,
              shop_name: response.name,
              tokenDropi: response.token,
            });
          });
        }
      });

      this.orders.sort(
        (a: any, b: any) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
      );
      this.loadingOrders = false;
    } catch (error) {
      // Manejar el error como desees
      this.$toast.error('Error de comunicación con órdenes de Dropi');
      //console.error("Ocurrió un error al obtener las órdenes:", error);
      // this.tokenDropiError = true;

      // Puedes mostrar un mensaje de error o tomar otras acciones
    }
  }
  translateKey(key: string): string {
    switch (key) {
      case 'name':
        return 'Nombre';
      case 'lastName':
        return 'Apellido';
      case 'phoneNumber':
        return 'Telefono';
      case 'address':
        return 'Dirección';
      case 'city':
        return 'Ciudad';
      case 'email':
        return 'Correo';
      case 'identification':
        return 'Identificación';
      case 'postalCode':
        return 'Codigo postal';
      default:
        return 'Sin definir';
    }
  }

  keyIcon(key: string): string {
    switch (key) {
      case 'name':
        return 'user';
      case 'lastName':
        return 'signature';
      case 'phoneNumber':
        return 'phone';
      case 'address':
        return 'map-marker';
      case 'city':
        return key;
      case 'email':
        return 'envelope';
      case 'identification':
        return 'id-card';
      case 'postalCode':
        return 'mail-bulk';
      default:
        return 'user';
    }
  }

  getArrayContactData(): void {
    this.arrayContactData = [];
    if (this.contactData) {
      const keys = Object.keys(this.contactData);
      keys.forEach(key => {
        if (key) {
          this.arrayContactData.push({
            originalKey: key,
            key: this.translateKey(key),
            value: (this.contactData as any)[key],
            icon: this.keyIcon(key),
          });
        }
      });
    }
  }

  @Watch('currentConversation')
  async watcherCurrentConversation(): Promise<any> {
    if (this.currentConversation.contactData) {
      this.contactData = _.cloneDeep(this.currentConversation.contactData);
    } else {
      this.contactData = {};
    }
    this.getArrayContactData();
    this.getOrders();
  }

  // endpoint llamada
  async startCall() {
    const response = await axios.post(`${this.irisUrl}${this.generarLlamada}`, {
      Token_v: '',
      IdProyecto_i: '',
      Extension_v: '102',
      Numero_v: '',
    });
    console.log(response);
  }
}
