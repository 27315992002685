import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import axios from 'axios';
import $ from 'jquery';
import { Agent } from '@/app/store/modules/admin/admin.models';
import { BASE_URL_MANAGER } from '@/config';

@Component({
  name: 'conversation-transfer',
})
export default class ConversationTransfer extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop() readonly conversation!: any;
  @Prop() readonly agents!: Agent[];
  @Prop() readonly openConversation!: number;
  @Prop() readonly fromAgent!: Agent;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string | undefined;

  public view = '';
  public VIEW_TRANSFER = 'transfer';
  public VIEW_CLOSE = 'close';
  public selectedAgent: any = {};
  public selectedAgentId: string = '';

  mounted(): void {
    console.log('mounted');
    console.log(this.agents);
    console.log(this.fromAgent);
  }

  unmounted() {
    console.log('unmounted');
    this.cleanComponent();
  }
  async transferConversation(view: string) {
    try {
      if (view === this.VIEW_TRANSFER) {
        const baseUrl = `${BASE_URL_MANAGER}/agent/changeStatus/${this.fromAgent._id}`;
        await axios.delete(baseUrl, {
          data: {
            toAgent: this.selectedAgent,
          },
        });
        this.$emit('conversation-transfered', {
          conversation: this.conversation,
          agent: this.selectedAgent,
        });

        this.disable();
      } else if (view === this.VIEW_CLOSE) {
        const baseUrl = `${BASE_URL_MANAGER}/agent/changeStatusClose/${this.fromAgent._id}`;
        await axios.delete(baseUrl);
        this.$emit('conversation-closed', {
          conversation: this.conversation,
        });

        this.disable();
      }
      this.$notification.success({
        message: 'Transferencia de conversación',
        description: 'La conversación ha sido transferida con éxito',
        placement: 'bottomRight',
      });
    } catch (error) {
      console.log(error);
    }
  }

  get listAgents(): Agent[] {
    const agents = this.agents.filter(
      agent => agent._id !== this.fromAgent._id,
    );
    console.log('listAgents', agents);

    return agents;
  }
  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');

    modal = $(enableId);
    modal.modal('show');
  }
  disable() {
    if (this.autoDisable) {
      $('#' + this.elementId).modal('hide');
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
    this.cleanComponent();
  }

  changeView(view: string) {
    this.view = view;
  }

  cleanComponent() {
    this.selectedAgent = {};
    this.selectedAgentId = '';
    this.view = '';
  }
}
