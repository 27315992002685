import {
  BASE_URL_ORCHESTATOR,
  BASE_URL_WHATSAPP_CLOUD,
} from './../../../config';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import Axios, { AxiosError, CancelToken, CancelTokenSource } from 'axios';
import { BASE_URL_MANAGER } from '@/config';
import { store, storeTypes } from '@/app/store';
import Swal from 'sweetalert2';
import $ from 'jquery';

import { conversationActionsTypes } from '@/app/store/modules/conversation/conversation.actions';
import { conversationMutationsTypes } from '@/app/store/modules/conversation/conversation.mutations';
import CustomButton from '../../components/conversation/actions/custom-button.vue';

import { MazPhoneNumberInput } from 'maz-ui';

// Models
import {
  BusinessStatus,
  Conversation,
  ConversationNewEntryQueue,
  Interaction,
  InteractionOrigin,
  MessageType,
} from '@/app/store/modules/conversation/conversation.models';
// Components
import ImageHolderModal from '@/app/components/shared/image-holder-modal/ImageHolderModal.vue';
import AgentSidebar from '@/app/views/agent-sidebar/agent-sidebar.vue';
import ConversationFormSend from '@/app/components/conversation/conversationFormSend/ConversationFormSend.vue';
import ConversationContent from '@/app/components/conversation/conversationContent/ConversationContent.vue';
import NewQuickResponseModal from '@/app/components/agents-management/dedicated-components/new-quick-response-modal/NewQuickResponseModal.vue';
import BusinessStatusSelector from '@/app/components/chat/business-status-selector/BusinessStatusSelector.vue';
import ChangeConversationStatusModal from '@/app/components/chat/change-conversation-status-modal/ChangeConversationStatusModal.vue';
import ResolveConversationModal from '@/app/components/chat/resolve-conversation-modal/ResolveConversationModal.vue';
import ConversationsCategoryList from '@/app/components/chat/conversations-category-list/ConversationsCategoryList.vue';
import ConversationsCategoryTabs from '@/app/components/chat/conversations-category-tabs/ConversationsCategoryTabs.vue';
import SearchConversationWidget from '@/app/components/chat/search-conversation-widget/SearchConversationWidget.vue';
import TransferConversationModal from '@/app/components/chat/transfer-conversation-modal/TransferConversationModal.vue';
import ContactDataManager from '@/app/components/chat/contact-data-manager/ContactDataManager.vue';
import DropiInfoManager from '@/app/components/chat/dropi-info-manager/DropiInfoManager.vue';
import FilterComponent from '@/app/components/filter-view/Filter.vue';
import ModalLlamadas from '@/app/components/llamadas/modal/ModalLlamadas.vue';
import Waiting from '../waiting/waiting.vue';
import ConversationTransfer from '@/app/components/agents-management/conversation-transfer/ConversationTransfer.vue';
//import { Navigation, Pagination } from 'swiper'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
//import 'swiper/swiper-bundle.css'

const _ = require('lodash');
import { Swiper, SwiperSlide } from 'swiper-vue2';
import eventBus from '@/main';
import { agentActionsTypes } from '@/app/store/modules/agent/agent.actions';
import { filterMutationsTypes } from '@/app/store/modules/filters/filters.mutations';
import { IProject } from '@/app/store/modules/user/user.models';

require('@/assets/css/app.css');
//SwiperCore.use([Navigation, Pagination])
interface FiltersConfig {
  label: string;
  value: string;
  icon: string;
  config: [
    {
      name_filter: string;
      type_filter: string;
      source: string;
      selectedOption: any;
    },
  ];
}

@Component({
  name: 'agent-chat',
  components: {
    PerfectScrollbar,
    ConversationContent,
    ConversationFormSend,
    NewQuickResponseModal,
    ImageHolderModal,
    BusinessStatusSelector,
    ResolveConversationModal,
    ChangeConversationStatusModal,
    ConversationsCategoryList,
    ConversationsCategoryTabs,
    SearchConversationWidget,
    ContactDataManager,
    DropiInfoManager,
    TransferConversationModal,
    AgentSidebar,
    Swiper,
    SwiperSlide,
    CustomButton,
    MazPhoneNumberInput,
    FilterComponent,
    ModalLlamadas,
    Waiting,
    ConversationTransfer,
    VNodes: (_, attrs: any) => {
      return attrs.vnodes;
    },
  },
})
export default class AgentChat extends VueWizard {
  public filters_resueltas: any[] = [];
  public filters_activas: any[] = [];
  public filters_auto: any[] = [];

  public currentFilters: any = null;

  public activeTab = 'encola';
  public whatsappicon = '';
  public spinConversation = false;
  public currentList = 2;
  public unsubscribe: any;
  public visible = false;
  public EventBus: Vue = new Vue();
  public agentQuickResponses: any = [];
  public topicQuickResponses: any = [];
  public currentAgentId = '';
  public filterConversation = false;
  public formatedFileName: any = '';
  public dataFileUrl: any = '';
  public objectConversationUpdate: any = null;
  public maxHeightSubstract = 0;

  public topicFilterValue = '';
  public alertIsOpen: any = false;

  public showRefreshText = false;
  public showChangeStatusText = false;
  public showResolveText = false;

  // public businessStatuses: BusinessStatus[] = [];
  public showStatusSelector = false;
  public showSelectStatusModal = false;
  public selectedStatus: BusinessStatus | null = null;
  public showResolveConversationModal = false;

  public activeBusinessStatusFilterValue = '';
  public inacttivedBusinessStatusFilterValue = '';
  public resolvedBusinessStatusFilterValue = '';
  public autoBusinessStatusFilterValue = '';
  public previousBusinessStatusFilterValue = '';
  public previousActiveBusinessStatusFilterValue = '';
  public previousInacttivedBusinessStatusFilterValue = '';
  public previousResolvedBusinessStatusFilterValue = '';
  public previousAutoBusinessStatusFilterValue = '';
  public previousTopicFilterValue = '';
  public loadingFilters = true;
  public loadingFiltersActive = false;
  public loadingFiltersResolved = false;
  public loadingFiltersAuto = false;

  public resolvedActiveFilters: string[] = [];
  public inactivedActiveFilters: string[] = [];
  public activeActiveFilters: string[] = [];
  public autoActiveFilters: string[] = [];

  public showTransferConversationModal = false;
  public showTransferText = false;
  public queueActiveFilters: string[] = [];

  public currentTab = 'encola';

  public isError = false;
  public errors: any = [];
  public activeKey = '1';
  public dropiInfoManagerKey = 0;
  public dropiIncidenceStatus = {
    reconnecting: false,
    reconnectingIntervalId: 0,
    reconnectingRemainingTime: 15,
  };

  public templates: any = [];
  public startConverstionObject: any = {
    phone: '',
    name: '',
    text: '',
  };
  public tempPhone = '';
  public defaultPhoneNumber = '';
  // Preferences countris
  public countries: string[] = [
    'CO',
    'MX',
    'AR',
    'CL',
    'PE',
    'EC',
    'VE',
    'ES',
    'PY',
  ];
  public translations = {
    countrySelectorLabel: 'Selecciona tu país',
    countrySelectorError: 'Selecciona tu país',
    phoneNumberLabel: 'Número de teléfono',
    example: 'Ejemplo :',
    countrySelector: {
      placeholder: 'Country codes',
      error: 'Choose countrys',
      searchPlaceholder: 'Search the countrys',
    },
  };
  public isValid = false;
  public data: any;
  public textTooltip =
    'Crea filtros avanzados que se aplicarán unicamente en la bandeja cola.';
  public filterDefault: FiltersConfig = {
    label: 'Todos',
    value: 'all',
    icon: 'mdi-filter-outline',
    config: [
      {
        name_filter: 'Todos',
        type_filter: 'all',
        source: 'mdi-filter-outline',
        selectedOption: [],
      },
    ],
  };

  // dropdown menu
  public orderOptions: {
    label: string;
    value: string;
  }[] = [
    {
      label: 'Más reciente',
      value: 'newest',
    },
    {
      label: 'Más antigua',
      value: 'oldest',
    },
  ];
  public orderSelectedActivas: any = 'Order';
  public orderSelectedEncola: any = 'Order';
  public orderSelectedResueltas: any = 'Order';
  public orderSelectAuto: any = 'Order';
  public showFilter: boolean[] = [];

  public response_filter: any = null;
  private searchTimer: any;
  private searchDelay = 500;
  private source: CancelTokenSource = Axios.CancelToken.source();

  // Calls
  public phoneNumber = '';
  public keypadRows = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#'],
  ];

  public showKeypad = false;
  public showModalCalls = false;

  public showCheckboxQueue = false;
  public conversationSelectedQueue = 0;
  public convSelectedQueue: string[] = [];

  public showCheckboxOpen = false;
  public conversationSelectedOpen = 0;
  public convSelectedOpen: string[] = [];

  public showCheckboxAuto = false;
  public conversationSelectedAuto = 0;
  public convSelectedAuto: string[] = [];

  public showActionsMenu = false;

  public showTransferClose = false;
  public loadingTrasfering = false;
  public isSelectedAll = false;

  appendToPhoneNumber(phoneNumber: string) {
    this.phoneNumber += phoneNumber;
  }

  switchKeypad() {
    this.showKeypad = !this.showKeypad;
  }

  onUpdate(data: any) {
    this.data = data;
    // is valid
    this.isValid = data.isValid;
    this.defaultPhoneNumber = this.data.nationalNumber;
    this.tempPhone = this.data.nationalNumber;
  }

  onClear() {
    this.data = null;
    this.isValid = false;
  }

  public isMyConversation() {
    const currentAgent: string = this.$store.state.agent.agent._id;
    const currentConversationAgent: string =
      this.currentConversation.currentAgent;
    if (currentAgent === currentConversationAgent) {
      return true;
    } else {
      return false;
    }
  }

  public isCurrentConversationEmpty() {
    const currentConversation: any = this.currentConversation;

    if (currentConversation.status === '') {
      return true;
    }
    return false;
  }

  public isCurrentConversationQueued() {
    const currentConversation: any = this.currentConversation;
    if (
      currentConversation.status === 'IN_QUEUE' ||
      currentConversation.status === 'AUTO' ||
      currentConversation.status === 'NEW' ||
      currentConversation.status === 'NEW_AUTO'
    ) {
      return true;
    }
    return false;
  }

  public isCurrentConversationResolved() {
    const currentConversation: any = this.currentConversation;
    if (currentConversation.status === 'RESOLVED') {
      return true;
    }
    return false;
  }

  public isCurrentConversationOpen() {
    const currentConversation: any = this.currentConversation;
    if (
      currentConversation.status === 'ACTIVE' ||
      currentConversation.status === 'TRANSFERRED' ||
      currentConversation.status === 'AUTO'
    ) {
      return true;
    }
    return false;
  }
  public get showChatFooter() {
    const isEmpty = this.isCurrentConversationEmpty();
    const isOpen = this.isCurrentConversationOpen();
    const isMine = this.isMyConversation();

    if (!isEmpty && isOpen && isMine) {
      return true;
    }
    return false;
  }

  public get showActionButtons() {
    return this.isCurrentConversationQueued();
  }

  public get showRetakeConversationButton() {
    return this.isCurrentConversationResolved();
  }

  changeToAutoTabs() {
    store.commit(storeTypes.agent.mutations.setTabSelected('auto'));

    this.currentTab = 'auto';
  }
  changeToCola() {
    store.commit(storeTypes.agent.mutations.setTabSelected('encola'));
    this.currentTab = 'encola';
  }

  get isFilterActiveQueue() {
    return store.state.agent!.filterSelectedQueue.value !== 'all';
  }
  get isFilterActiveOpen() {
    return store.state.agent!.filterSelectedOpen.value !== 'all';
  }
  get isFilterActiveClose() {
    return store.state.agent!.filterSelectedClose.value !== 'all';
  }
  get isFilterActiveAuto() {
    return store.state.agent!.filterSelectedAuto.value !== 'all';
  }

  // public filters_encola: any[] = [];
  // public filters_encola_admin: any[] = [];

  get filters_encola() {
    return store.state.filters!.filters_agent || [];
  }
  get filters_encola_admin() {
    return store.state.filters!.filters_project || [];
  }

  get loadinConversation() {
    return store.state.agent!.loadingConversation;
  }

  // action buttons
  takeConversation() {
    this.grebConversation(this.currentConversation);
  }
  closeConversations() {
    eventBus.$emit('closeConversation', this.currentConversation);
  }
  transferConversation() {
    eventBus.$emit('transferConversation', this.currentConversation);
  }

  callConversation() {
    this.showModalCalls = !this.showModalCalls;
  }

  changeOrder(value: any, category: string) {
    if (category == 'activas') {
      this.orderSelectedActivas = value;
      const order = -this.parseOrder(value.value);
      // set pageopen to 0
      this.$store.dispatch(agentActionsTypes.setPageOpenConversations(0));
      this.$store.dispatch(agentActionsTypes.setOrderConversations(order));
      this.$store.dispatch(agentActionsTypes.resetOpenConversations());
      if (store.state.agent!.filterSelectedOpen.value === 'all') {
        this.$store.dispatch(
          agentActionsTypes.loadAgentOpenConversations({
            agent_id: store.state.agent!.agent._id,
            page: 0,
            limit: 50,
            sort: order,
          }),
        );
      }
      this.$store.dispatch(agentActionsTypes.orderConversations(order + ''));
    } else if (category === 'encola') {
      this.orderSelectedEncola = value;
      const order = this.parseOrderClose(value.value);
      // set pagequeue to 0
      this.$store.dispatch(agentActionsTypes.setPageQueuesConversations(0));
      this.$store.dispatch(
        agentActionsTypes.setOrderQueuesConversations(order),
      );
      this.$store.dispatch(agentActionsTypes.resetQueueConversations());
      if (store.state.agent!.filterSelectedQueue.value === 'all') {
        this.$store.dispatch(
          agentActionsTypes.loadAgentQueues({
            subscriptions: store.state.agent!.agent.subscriptions,
            page: 0,
            sort: order,
          }),
        );
      }

      this.$store.dispatch(
        agentActionsTypes.orderQueuesConversations(
          this.orderSelectedEncola.value,
        ),
      );
    } else if (category === 'resueltas') {
      this.orderSelectedResueltas = value;
      const order = this.parseOrder(value.value);

      //set pagequeue to 0
      this.$store.dispatch(agentActionsTypes.setPageCloseConversations(0));
      this.$store.dispatch(agentActionsTypes.setOrderCloseConversations(order));
      this.$store.dispatch(agentActionsTypes.resetCloseConversations());
      // agentCloseConversations = []
      this.$store.dispatch(
        agentActionsTypes.loadAgentCloseConversations({
          agent_id: store.state.agent!.agent._id,
          page: 0,
          sort: -order,
        }),
      );
      this.$store.dispatch(
        agentActionsTypes.orderCloseConversations(
          this.orderSelectedResueltas.value,
        ),
      );
    } else if (category === 'auto') {
      this.orderSelectAuto = value;
      const order = this.parseOrder(value.value);
      // set pageopen to 0
      this.$store.dispatch(agentActionsTypes.setPageAutoConversations(0));
      this.$store.dispatch(agentActionsTypes.setOrderAutoConversations(order));
      this.$store.dispatch(agentActionsTypes.resetAutoConversations());
      if (store.state.agent!.filterSelectedAuto.value === 'all') {
        this.$store.dispatch(
          agentActionsTypes.loadAgentAutoConversations({
            subscriptions: store.state.agent!.agent.subscriptions,
            page: 0,
            sort: -order,
          }),
        );
      }
      this.$store.dispatch(
        agentActionsTypes.orderAutoConversations(this.orderSelectAuto.value),
      );
    }
  }
  parseOrder(value: any) {
    if (value == 'newest') {
      return 1;
    } else if (value == 'oldest') {
      return -1;
    }
    return 1;
  }
  parseOrderClose(value: any) {
    if (value == 'oldest') {
      return 1;
    } else if (value == 'newest') {
      return -1;
    }
    return 1;
  }
  constructor() {
    super();
  }
  notifyOpenModal() {
    eventBus.$emit('openModal');
  }

  changeActiveKey(key: string) {
    this.activeKey = key;
  }
  statusColorStyle(status: BusinessStatus): any {
    const rgb = this.hexToRgb(status.color);
    return {
      '--label-bg-color-r': rgb.r || 0,
      '--label-bg-color-g': rgb.g || 0,
      '--label-bg-color-b': rgb.b || 0,
    };
  }

  async onResolveNormally(): Promise<void> {
    this.showResolveConversationModal = false;
    await this.closeConversation();
    Vue.$toast.success('La conversacion ha sido cerrada', {
      timeout: 2000,
    });
  }

  async onResolveByBusinessStatus(event: BusinessStatus): Promise<void> {
    this.showResolveConversationModal = false;
    await this.changeConversationStatus(event);
    await this.closeConversation();
    Vue.$toast.success('La conversacion ha sido cerrada', {
      timeout: 2000,
    });
  }

  onSelectedStatus(event: BusinessStatus): void {
    this.selectedStatus = event;
    this.showStatusSelector = false;
    this.showSelectStatusModal = true;
  }

  onModalNoConfirm(): void {
    this.selectedStatus = null;
    this.showSelectStatusModal = false;
    this.showResolveConversationModal = false;
    this.showTransferConversationModal = false;
  }

  async changeConversationStatus(event: BusinessStatus): Promise<any> {
    this.selectedStatus = null;
    this.showSelectStatusModal = false;
    store.commit(conversationMutationsTypes.setLoading(true));
    return new Promise((resolve, reject) => {
      store
        .dispatch(conversationActionsTypes.changeConversationStatus(event))
        .then(async () => {
          this.$notification.success({
            placement: 'bottomRight',
            message: 'Cambio realizado',
            description: `El estado de la conversación ha cambiado`,
          });
          resolve(true);
        })
        .catch(error => {
          this.$notification.error({
            message: 'Ha ocurrido un problema',
            description: error.message,
          });

          reject(true);
        })
        .finally(() =>
          store.commit(conversationMutationsTypes.setLoading(false)),
        );
    });
  }

  get noFinishBusinessStatuses(): BusinessStatus[] {
    return this.businessStatuses.filter(
      (businessStatus: BusinessStatus) => !businessStatus.finish,
    );
  }

  get finishBusinessStatuses(): BusinessStatus[] {
    return this.businessStatuses.filter(
      (businessStatus: BusinessStatus) => businessStatus.finish,
    );
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }
  getBusinessStatus(status: string): BusinessStatus | undefined {
    return this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status,
    );
  }

  get filteredAgentQueue() {
    this.queueActiveFilters = [];

    if (this.topicFilterValue) {
      // if topicFilterValue is in filters, filters contains a label and a value

      return this.agentQueue.filter(
        (conversation: ConversationNewEntryQueue) =>
          this.getTopic(conversation) ===
          this.getFilterTopic(this.topicFilterValue),
      );
    } else {
      return this.agentQueue;
    }
  }
  // listen event

  openModelFilter() {
    this.$set(this.showFilter, 0, true);
    this.topicFilterValue = '';
    this.handleChangeQueueFilter('');
  }

  openModelFilterEdit(ele: any) {
    if (this.currentTab === 'encola') {
      const queueFilter = this.$refs.queueFilter as any;
      queueFilter.blur();
    } else if (this.currentTab === 'activas') {
      const openFilter = this.$refs.activeFilter as any;
      openFilter.blur();
    } else if (this.currentTab === 'resueltas') {
      const closeFilter = this.$refs.resolvedFilter as any;
      closeFilter.blur();
    } else if (this.currentTab === 'auto') {
      const autoFilter = this.$refs.autoFilter as any;
      autoFilter.blur();
    }

    this.currentFilters = ele;
    this.$set(this.showFilter, 0, true);
  }

  get filteredAgentQueueConversations(): Conversation[] {
    const conversations: Conversation[] = [];
    if (this.topicFilterValue === 'crear_filtro') {
      this.$set(this.showFilter, 0, true);
    }

    if (this.topicFilterValue !== 'crear_filtro') {
      this.filteredAgentQueue.forEach((queue: ConversationNewEntryQueue) => {
        queue.conversation.start_time = queue.start_time;
        conversations.push(queue.conversation);
      });
    }
    return conversations;
  }

  get noFilteredAgentQueueConversations(): Conversation[] {
    const conversations: Conversation[] = [];
    return conversations;
  }

  get actualPage(): number {
    return store.state.agent!.actualPage;
  }

  get agentTopics(): string[] {
    return store.state.agent!.agent.subscriptions;
  }

  get loadingQueueConversations(): boolean {
    return store.state.agent!.loadingQueue;
  }

  get loadingOpenConversations(): boolean {
    return store.state.agent!.loadingOpen;
  }

  get loadingCloseConversations(): boolean {
    return store.state.agent!.loadingClose;
  }

  get loadingAutoConversations(): boolean {
    return store.state.agent!.loadingAuto;
  }

  get arrayActiveConversationFilter(): Conversation[] {
    this.activeActiveFilters = [];
    let conversations: Conversation[] = this.agentConversations;

    if (this.filterConversation) {
      this.activeActiveFilters.push('No respondidas');
      conversations = this.agentConversations.filter(
        (filter: any) => filter.newinteractions > 0,
      );
    }
    if (this.activeBusinessStatusFilterValue === 'crear_filtro') {
      this.$set(this.showFilter, 0, true);
    }
    if (this.activeBusinessStatusFilterValue) {
      this.activeActiveFilters.push(this.activeBusinessStatusFilterValue);
      conversations = this.agentConversations.filter((filter: any) => {
        if (
          this.activeBusinessStatusFilterValue === 'TRANSFERRED' ||
          this.activeBusinessStatusFilterValue === 'ACTIVE'
        ) {
          return filter.status === this.activeBusinessStatusFilterValue;
        } else {
          return filter.bussinesStatus === this.activeBusinessStatusFilterValue;
        }
      });
    }
    const orderOpen = this.$store.state.agent!.orderOpen;
    if (orderOpen === -1) {
      return conversations;
    } else {
      return conversations.reverse();
    }
  }
  // listen emit events

  private listen(): void {
    eventBus.$on('Error-Interaction', (payload: any) => {
      if (payload.code === 'TIMEOUT' || payload.code === 131047) {
        this.isError = payload.isError;
        this.errors = payload.errors;
      } else {
        this.isError = false;
        this.errors = [];
      }
    });
    eventBus.$on('takeConversation', (conversation: any) => {
      this.grebConversation(conversation);
    });
    eventBus.$on('closeConversation', () => {
      this.showResolveConversationModal = true;
    });

    eventBus.$on('transferConversation', () => {
      this.showTransferConversationModal = true;
    });

    eventBus.$on(
      'selectedConversations',
      (conversation: { conversations: string[]; tam: number }) => {
        this.conversationSelectedQueue = conversation.tam;
        this.convSelectedQueue = conversation.conversations;
        this.conversationSelectedOpen = conversation.tam;
        this.convSelectedOpen = conversation.conversations;
        this.conversationSelectedAuto = conversation.tam;
        this.convSelectedAuto = conversation.conversations;
      },
    );
  }

  async mounted(): Promise<any> {
    store.commit(
      conversationMutationsTypes.setCurrentConversation({
        currentAgent: '',
        currentChannel: '',
        currentConsumer: '',
        currentInputInteraction: [],
        currentTopic: '',
        interactions: [],
        _id: '',
        newMessage: 0,
        variables: {},
        contactData: {},
        newinteractions: 0,
        updatedAt: '',
        createdAt: '',
        status: '',
        checked: false,
      }),
    );
    store.commit(filterMutationsTypes.setCurrentView('messages'));
    this.listen();
    this.retrieveQuickResponses();
    // change placeholder

    // Variable Declarations

    let chat_bar = $('.chat').width();
    let win_width = $(window).width();

    $(
      '.user-list-item:not(body.status-page .user-list-item, body.voice-call-page .user-list-item)',
    ).on('click', function () {
      if (win_width != undefined && win_width < 992) {
        $('.left-sidebar').addClass('hide-left-sidebar');
        $('.chat').addClass('show-chatbar');
      }
    });

    $('.dream_profile_menu').on('click', function () {
      $('.right-sidebar').addClass('show-right-sidebar');
      $('.right-sidebar').removeClass('hide-right-sidebar');
      if (
        win_width != undefined &&
        chat_bar != undefined &&
        win_width > 991 &&
        win_width < 1201
      ) {
        $('.chat:not(.right-sidebar .chat)').css('margin-left', -chat_bar);
      }
      if (win_width != undefined && win_width < 992) {
        $('.chat:not(.right-sidebar .chat)').addClass('hide-chatbar');
      }
    });

    jQuery('.close_profile').on('click', function () {
      $('.right-sidebar').addClass('hide-right-sidebar');
      $('.right-sidebar').removeClass('show-right-sidebar');
      if (win_width != undefined && win_width > 991 && win_width < 1201) {
        $('.chat').css('margin-left', 0);
      }
      if (win_width != undefined && win_width < 992) {
        $('.chat').removeClass('hide-chatbar');
      }
    });
    $('.nav-tabs a').on('click', function () {
      $(this).tab('show');
    });

    $('.chat-header .left_side i, .page-header .left_side i').on(
      'click',
      function () {
        $('.left-sidebar').removeClass('hide-left-sidebar');
        $('.chat').removeClass('show-chatbar');
      },
    );
    //Rightside accordian
    $('.accordion-col .accordion-title').on('click', function () {
      $(this).next().slideToggle();
      $(this).toggleClass('active');
    });
    //Custom modal click for status view
    $('*[data-target="#status-modal"]').on('click', function () {
      $('body').addClass('custom-model-open');
    });
    $('.custom-status-close').on('click', function () {
      $('body').removeClass('custom-model-open');
    });

    //Chat Search Visible
    $('.chat-search-btn').on('click', function () {
      $('.chat-search').addClass('visible-chat');
    });
    $('.close-btn-chat').on('click', function () {
      $('.chat-search').removeClass('visible-chat');
    });

    this.EventBus.$on('closeFilter', async (e: any) => {
      this.$set(this.showFilter, 0, false);
      this.currentFilters = null;
      const data = e[1];

      if (this.currentTab == 'activas') {
        this.activeBusinessStatusFilterValue =
          this.previousActiveBusinessStatusFilterValue;

        if (this.filters_activas.includes(data)) {
          this.activeBusinessStatusFilterValue = '';
        }
      } else if (this.currentTab == 'encola') {
        this.topicFilterValue = this.previousTopicFilterValue;

        const isInFilters = this.filters_encola.findIndex(
          filter => filter.value === this.topicFilterValue,
        );
        // if not in filters, set to default
        if (isInFilters === -1) {
          this.topicFilterValue = '';
          this.handleChangeQueueFilter('');
        }

        if (this.filters_encola.find(filter => filter.value === data)) {
          this.topicFilterValue = '';
          this.handleChangeQueueFilter('');
        }
      } else if (this.currentTab == 'resueltas') {
        this.resolvedBusinessStatusFilterValue =
          this.previousResolvedBusinessStatusFilterValue;
        if (this.filters_resueltas.includes(data)) {
          this.resolvedBusinessStatusFilterValue = '';
          this.handleChangeCloseFilter('');
        }
      } else if (this.currentTab == 'auto') {
        this.autoBusinessStatusFilterValue =
          this.previousAutoBusinessStatusFilterValue;
        if (this.filters_auto.includes(data)) {
          this.autoBusinessStatusFilterValue = '';
          this.handleChangeAutoFilter('');
        }
      }

      await this.getFilters();
    });

    this.EventBus.$on('saveFilter', async (event: any) => {
      this.$set(this.showFilter, 0, false);
      await this.getFilters();
      const data = event[1];
      const editFilter = event[2];
      const filterEdited = event[3];

      if (editFilter) {
        switch (data.inbox) {
          case 'activas':
            // select the last filter
            this.activeBusinessStatusFilterValue =
              this.filters_activas[this.filters_activas.length - 1].value;
            this.handleChangeActiveFilter(
              this.filters_activas[this.filters_activas.length - 1].value,
            );
            break;
          case 'encola':
            this.topicFilterValue =
              this.filters_encola[this.filters_encola.length - 1].value;
            this.handleChangeQueueFilter(
              this.filters_encola[this.filters_encola.length - 1].value,
            );
            break;
          case 'resueltas':
            this.resolvedBusinessStatusFilterValue =
              this.filters_resueltas[this.filters_resueltas.length - 1].value;
            this.handleChangeCloseFilter(
              this.filters_resueltas[this.filters_resueltas.length - 1].value,
            );
            break;
          case 'auto':
            //
            this.autoBusinessStatusFilterValue =
              this.filters_auto[this.filters_auto.length - 1].value;
            this.handleChangeAutoFilter(
              this.filters_auto[this.filters_auto.length - 1].value,
            );

            break;
        }
      } else {
        switch (data.inbox) {
          case 'activas':
            // select the edited filter
            this.activeBusinessStatusFilterValue = filterEdited;
            this.handleChangeActiveFilter(filterEdited);
            break;
          case 'encola':
            this.topicFilterValue = filterEdited;
            this.handleChangeQueueFilter(filterEdited);
            break;
          case 'resueltas':
            this.resolvedBusinessStatusFilterValue = filterEdited;
            this.handleChangeCloseFilter(filterEdited);
            break;
          case 'auto':
            this.autoBusinessStatusFilterValue = filterEdited;
            this.handleChangeAutoFilter(filterEdited);
            break;
        }
      }
      // active filter
    });
    this.EventBus.$on('llamada', () => {
      this.showModalCalls = false;
    });
    // delay 500ms to load conversations
    await this.getFilters();
  }

  get businessStatuses() {
    return store.getters.project.bussinesStatusList
      ? store.getters.project.bussinesStatusList
      : [];
  }

  resolveResultConversation(conversation: any): void {
    if (conversation.conversation) {
      conversation = conversation.conversation;
    }

    this.$root.$emit('closeReply', true);
    this.objectConversationUpdate = conversation;
    store.commit(
      storeTypes.conversation.mutations.setCurrentConversation(conversation),
    );
  }

  async loadAgent(): Promise<any> {
    await store.dispatch(
      storeTypes.agent.actions.loadAgent(store.state.user!.user._id),
    );
    store.dispatch(storeTypes.agent.actions.setPageCloseConversations(0));
    store.dispatch(storeTypes.agent.actions.setPageOpenConversations(0));
    store.dispatch(storeTypes.agent.actions.setPageQueuesConversations(0));
    store.dispatch(storeTypes.agent.actions.setPageAutoConversations(0));
  }

  loading(): any {
    return store.state.user!.loading;
  }

  get FullName(): string {
    const firstName = this.user.name.firstName || '';
    const lastName = this.user.name.lastName || '';
    return `${firstName} ${lastName}`;
  }

  get user(): any {
    return store.state.user!.user;
  }

  get agent(): any {
    return store.state.agent!.agent;
  }

  get agentOpenConversations(): any {
    return store.state.agent!.agentOpenConversations;
  }

  get agentAutoConversations(): any {
    return store.state.agent!.agentAutoConversations;
  }

  get currentCloseConversations(): any {
    return store.state.agent!.agentCloseConversations.filter(
      conversation =>
        conversation.currentConsumer ===
        store.state.conversation!.currentConversation.currentConsumer,
    );
  }

  get agentCloseConversations(): any {
    return store.state.agent!.agentCloseConversations;
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  get agentQueue(): any {
    return store.state.agent!.agentQueue.filter(
      (conversation: ConversationNewEntryQueue) =>
        conversation.conversation.updatedAt,
    );
  }
  public get currentTabActive() {
    const tab = this.$store.state.agent.tabSelected;
    if (tab === 'activas') {
      this.$nextTick(() => {
        this.$el.scrollTop =
          this.$el.scrollHeight - this.$el.clientHeight + 300;
      });
      return true;
    } else {
      return false;
    }
  }
  get error(): any {
    return store.state.conversation!.error;
  }

  get isReturnConversation(): Conversation | null {
    return store.state.conversation!.conversationReturn;
  }

  get agentConversations(): any {
    let conversations = this.agentOpenConversations.concat(
      Array.from(this.agentCloseConversations).reverse(),
    );
    conversations = conversations.filter(
      (conversation: any) => !conversation.isClosed,
    );
    return _.uniqBy(conversations, 'currentConsumer').filter(
      (conversation: any) => conversation.updatedAt,
    );
    /* .sort((a: any, b: any) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())*/
  }

  get autoConversation(): any {
    const conversations = Array.from(this.agentAutoConversations).reverse();
    return _.uniqBy(conversations, 'currentConsumer').filter(
      (conversation: any) => conversation.updatedAt,
    );
  }

  get completeConversations(): any {
    const openNumbers: any[] = [];
    const conversations: any[] = [];

    this.agentOpenConversations
      .concat(Array.from(this.agentCloseConversations).reverse())
      .forEach((conversation: any) => {
        if (!conversation.isClosed) {
          openNumbers.push(conversation.currentConsumer);
        } else if (!openNumbers.includes(conversation.currentConsumer)) {
          conversations.push(conversation);
        }
      });

    return _.uniqBy(conversations, 'currentConsumer').filter(
      (conversation: any) => conversation.updatedAt,
    );
  }

  get filteredAutoConversation(): Conversation[] {
    let conversations = this.autoConversation;

    this.autoActiveFilters = [];

    if (this.autoBusinessStatusFilterValue) {
      this.autoActiveFilters.push(this.autoBusinessStatusFilterValue);
      conversations = conversations.filter((filter: any) => {
        if (this.autoBusinessStatusFilterValue === 'RESOLVED') {
          return filter.status === this.autoBusinessStatusFilterValue;
        } else {
          return filter.bussinesStatus === this.autoBusinessStatusFilterValue;
        }
      });
    }

    return conversations.reverse();
  }
  get filteredCompleteConversations(): Conversation[] {
    let conversations = this.completeConversations;
    this.resolvedActiveFilters = [];
    if (this.resolvedBusinessStatusFilterValue === 'crear_filtro') {
      this.$set(this.showFilter, 0, true);
    }
    if (
      this.resolvedBusinessStatusFilterValue &&
      this.resolvedBusinessStatusFilterValue !== 'crear_filtro'
    ) {
      this.resolvedActiveFilters.push(this.resolvedBusinessStatusFilterValue);
      conversations = conversations.filter((filter: any) => {
        if (this.resolvedBusinessStatusFilterValue === 'RESOLVED') {
          return filter.status === this.resolvedBusinessStatusFilterValue;
        } else {
          return (
            filter.bussinesStatus === this.resolvedBusinessStatusFilterValue
          );
        }
      });
    }
    return conversations.reverse();
  }
  get filteredInactiveConversations(): Conversation[] {
    let conversations = this.completeConversations;
    this.inactivedActiveFilters = [];
    if (this.inacttivedBusinessStatusFilterValue) {
      this.inactivedActiveFilters.push(
        this.inacttivedBusinessStatusFilterValue,
      );
      conversations = conversations.filter((filter: any) => {
        if (this.inacttivedBusinessStatusFilterValue === 'RESOLVED') {
          return filter.status === this.inacttivedBusinessStatusFilterValue;
        } else {
          return (
            filter.bussinesStatus === this.inacttivedBusinessStatusFilterValue
          );
        }
      });
    }
    return conversations;
  }

  created(): void {
    this.EventBus.$on('image-modal', (values: any) => {
      setTimeout(() => this.enable(this.formatedFileName), 500);
      this.formatedFileName = values.formatedFileName;
      this.dataFileUrl = values.dataFileUrl;
    });

    this.unsubscribe = store.subscribe(mutation => {
      if (mutation.type === 'setAgent') {
        this.retrieveQuickResponses();
        this.currentAgentId = this.agent._id;
      }
    });

    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'm0pvd5xx',
      email: this.user!.username,
      name: this.user!.name!.firstName + ' ' + this.user!.name!.lastName,
      chatcenter: 'SI',
      nombre_chatcenter: this.user!.company!.name,
      id_tienda_chatcenter: this.user!.company!._id,
    };
    (function () {
      let w = window;
      let ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        let d = document;
        let i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        let l = function () {
          let s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/m0pvd5xx';
          let x: any = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
          l();
        } else if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  beforeDestroy(): void {
    this.unsubscribe();
  }

  retrieveQuickResponses(): void {
    this.retrieveAgentQuickResponses();
    this.retrieveTopicQuickResponses();
  }

  async retrieveAgentQuickResponses() {
    let url = `${BASE_URL_MANAGER}/quickResponse/${this.agent._id}`;
    try {
      const response = await Axios.get(url);
      this.agentQuickResponses = response.data;
    } catch (error) {
      this.agentQuickResponses = [];
    }
  }

  async retrieveTopicQuickResponses(): Promise<void> {
    const actualAgent = store.state.agent!.agent;
    const project = actualAgent.subscriptions[0].split('_')[0];
    const url = `${BASE_URL_MANAGER}/topic/${project}`;

    this.topicQuickResponses = [];

    try {
      const res = await Axios.get(url);
      const projectTopics = res.data.projectTopics;

      const userSubscriptions = actualAgent.subscriptions.map(sub =>
        sub.split('_').slice(1).join('_'),
      );
      const suscribedTopics = projectTopics
        .filter((projectTopic: any) =>
          userSubscriptions.includes(projectTopic.name),
        )
        .map((topic: any) => topic._id);

      const topicQuickResponsesId: any = [];
      for (const topic of suscribedTopics) {
        const urlQuickResponses = `${BASE_URL_MANAGER}/quickResponse/topic/${topic}`;
        const res = await Axios.get(urlQuickResponses);

        if (res) {
          for (const quickResponse of res.data) {
            if (!topicQuickResponsesId.includes(quickResponse._id)) {
              topicQuickResponsesId.push(quickResponse._id);
              this.topicQuickResponses.push(quickResponse);
            }
          }
        } else {
          this.topicQuickResponses = [];
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  deleteQuickResponse(values: any): void {
    let quickResponseId = values.quickResponseId;
    let url = `${BASE_URL_MANAGER}/quickResponse/${quickResponseId}`;
    Axios.delete(url).then(res => {
      if (res.status == 200) {
        this.$notification.success({
          placement: 'bottomRight',
          message: 'Respuesta rápida eliminada exitosamente',
          description: 'Los datos han sido eliminados',
        });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al guardar al información ${res.status}`,
          description: `Error: ${res.status}`,
        });
      }
      this.retrieveQuickResponses();
    });
  }

  getTopic(item: ConversationNewEntryQueue): string {
    return this.cleanTopic(item.topic || item.currentTopic || '');
  }

  getFilterTopic(item: string): string {
    return this.cleanTopic(item);
  }

  cleanTopic(topic: string): string {
    if (topic.includes('_')) {
      const topicElements = topic.split('_');
      return topicElements.slice(2).join(' ').trim();
    }
    return topic;
  }
  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer || item.consumer || '';
    const [name] = consumer.split('@');
    return name;
  }

  getUserName(item: any): any {
    const conversation: Conversation = item.variables
      ? item
      : item.conversation;
    const userName = conversation.variables.CONSUMER_NAME.value || '';
    return userName;
  }

  getUserPicture(item: any): any {
    const conversation: Conversation = item.variables
      ? item
      : item.conversation;
    const userName = conversation.variables.CONSUMER_NAME.value;

    if (userName) {
      return `https://ui-avatars.com/api/?name=${userName}&size=64&background=e0f5ff&color=007ab4&font-size=0.25&bold=true`;
    }

    return '';
  }

  async closeConversation(): Promise<any> {
    await store.dispatch(storeTypes.conversation.actions.closeConversation());
    const currentConversation =
      store.state.agent!.agentCloseConversations.length;
    store.dispatch(
      storeTypes.conversation.actions.setTotalCloseConversation(
        currentConversation,
      ),
    );
    this.currentList = 3;
  }

  get interactions(): Interaction[] {
    return store.state.agent!.interactions;
  }

  grebConversation(conversation: any): void {
    // Remove class from body
    setTimeout(() => {
      $('body').removeClass('swal2-height-auto');
    }, 75);

    store.commit(storeTypes.agent.mutations.setTabSelected('activas'));
    this.changeTab('activas');

    store
      .dispatch(storeTypes.conversation.actions.grebConversation(conversation))
      .then(response => {
        if (response) {
          this.currentList = 2;
          if (
            store.state.agent!.agentQueueTotal <=
            store.state.agent!.agentQueuePerPage
          ) {
            store.dispatch(
              storeTypes.agent.actions.loadAgentQueues({
                subscriptions: store.state.agent!.agent.subscriptions,
                page: store.state.agent!.actualPage,
                restartQueue: true,
              }),
            );
          }

          store.dispatch(
            storeTypes.agent.actions.updateTotalConversationsOpen(
              store.state.agent!.agentOpenConversations.length,
            ),
          );
          store.dispatch(agentActionsTypes.orderConversations('newest'));
          store.dispatch(
            conversationMutationsTypes.setCurrentConversation(conversation),
          );
        } else {
          this.fireSwalGrabError();
        }
      })
      .catch(this.fireSwalGrabError);
  }

  async transferConversationToAgent(fullName: string): Promise<void> {
    await this.sendQuickResponse([
      `La conversación ha sido transferida a ${fullName}`,
    ]);
  }

  async fireSwalGrabError(): Promise<void> {
    setTimeout(() => {
      $('body').removeClass('swal2-height-auto');
    }, 75);
    await Swal.fire({
      icon: 'error',
      title: 'Ha ocurrido un problema',
      text: 'Alguien mas tomo esta conversación antes que tu',
    });
  }

  getConversation(item: Conversation): void {
    const currentConversation = store.state.conversation!.currentConversation;
    if (currentConversation && currentConversation._id === item._id) {
      return;
    }
    this.$root.$emit('closeReply', true);
    this.objectConversationUpdate = item;
    // remove interactions
    store.commit(storeTypes.agent.mutations.setInteractions([]));
    store.commit(
      storeTypes.conversation.mutations.setCurrentConversation(item),
    );
    this.forceRenderDropiInfoManager();
  }

  get transferredToAgent(): { agent: any; conversation: Conversation } | null {
    return store.state.agent!.transferredToAgent;
  }

  @Watch('transferredToAgent')
  async conversationTransferred(): Promise<void> {
    if (this.transferredToAgent) {
      const name = this.getUserName(this.transferredToAgent.conversation)
        ? this.getUserName(this.transferredToAgent.conversation)
        : this.getConsumer(this.transferredToAgent.conversation);
      const agentName = await this.getAgentFullName(
        this.transferredToAgent.agent.user,
      );
      this.$notification.success({
        placement: 'TopRight',
        message: 'Conversación transferida',
        description: `${agentName} te ha transferido la conversación de ${name}`,
      });
    }
  }

  async getAgentFullName(userId: string): Promise<string> {
    const response = await Axios.get(`${BASE_URL_MANAGER}/user/${userId}`);
    return `${response.data.user.name.firstName} ${response.data.user.name.lastName}`;
  }

  @Watch('currentConversation')
  async getConversationUpdate(): Promise<any> {
    store.commit(storeTypes.agent.mutations.setActualPage(0));
    store.commit(storeTypes.agent.mutations.setTotalPages(0));
  }

  async loadInteractions(page: number): Promise<any> {
    await store.dispatch(
      storeTypes.agent.actions.loadActualInteractions({
        conversationId: this.currentConversation._id,
        page,
      }),
    );
  }

  async sendQuickResponseMessage(send: string): Promise<boolean> {
    const currentTextMessage: any = [send];
    const idTextMessage = Math.random().toString(36).substring(2, 9);

    this.EventBus.$emit('sendingMessage', idTextMessage);

    await store.dispatch(
      storeTypes.conversation.actions.sendMessage({
        id: idTextMessage,
        type: MessageType.MESSAGE,
        text: currentTextMessage,
        origin: InteractionOrigin.RSP,
      }),
    );

    this.EventBus.$emit('sentMessage', idTextMessage);

    return true;
  }

  async sendQuickResponseFile(
    send: any,
    fileName: any,
    fileType: any,
  ): Promise<any> {
    const currentFileUrl: string = send;
    const idTextMessage = Math.random().toString(36).substring(2, 9);
    this.EventBus.$emit('sendingMessage', idTextMessage);
    if (fileType === 'image') {
      await store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.IMAGE,
          fileurl: currentFileUrl,
          filename: fileName,
          origin: InteractionOrigin.RSP,
        }),
      );
    } else if (fileType === 'file') {
      await store.dispatch(
        storeTypes.conversation.actions.sendMessage({
          id: idTextMessage,
          type: MessageType.FILE,
          fileurl: currentFileUrl,
          filename: fileName,
          origin: InteractionOrigin.RSP,
        }),
      );
    }
    this.EventBus.$emit('sentMessage', idTextMessage);
  }

  async sendQuickResponse(values: any): Promise<any> {
    const message = values.message;
    if (values.fileName == '') {
      this.sendQuickResponseMessage(message);
    } else {
      const url = values.url;
      if (url == '') {
        this.sendQuickResponseMessage(message);
      } else {
        this.sendQuickResponseMessage(message).then(() => {
          this.sendQuickResponseFile(url, values.fileName, values.fileType);
        });
      }
    }
  }
  changeTab(e: string) {
    store.commit(storeTypes.agent.mutations.setTabSelected(e));
    this.currentTab = e;
    this.showCheckboxAuto = false;
    this.showCheckboxQueue = false;
    this.showCheckboxOpen = false;
    this.showActionsMenu = false;
  }

  enable(id: string): void {
    $(`#${id}`).modal('show');
  }

  disable(id: string): void {
    $(`#${id}`).modal('hide');
  }

  resultConversationToActive(item: any): void {
    setTimeout(() => {
      $('body').removeClass('swal2-height-auto');
    }, 75);
    Swal.fire({
      title: 'Conversación reactivable',
      icon: 'info',
      html: `¿Deseas reactivar la conversación con <b>${item.variables.CONSUMER_NAME.value}</b>?<br> <small class="text-info">(Se le enviara un mensaje de confirmación)</small>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Aceptar',
    }).then(async result => {
      if (result.value) {
        await store.dispatch(
          storeTypes.conversation.actions.returnConversation(item._id),
        );
      }
    });
  }

  @Watch('isReturnConversation')
  setOpenList() {
    if (this.isReturnConversation) {
      this.currentList = 2;
      this.$notification.open({
        placement: 'topRight',
        message: 'Conversación reactivada!',
        description: `El usuario ${this.getUserName(
          this.isReturnConversation,
        )} ha reactivado la conversación!`,
        icon: 'info',
      });
    }
  }

  /**
   * abre el dialogo de conversacion para inciar ocnversacion con clientes que no han inciado conversacion con nosotros
   */
  openStartConversationDialog(): void {
    this.startConverstionObject = {
      phone: '',
      name: '',
      text: '',
    };
    this.tempPhone = '';
    this.defaultPhoneNumber = this.tempPhone;

    // clear mzphone input using clear event
    this.data = [];

    if (this.templates.length == 0) {
      this.getTemplates();
    }
    let modal: any = $('#start-conversation');
    modal.modal('show');
  }

  isInvalidValidStartConversationForm() {
    return (
      !this.isValid ||
      !this.startConverstionObject.text ||
      !this.tempPhone ||
      !this.startConverstionObject.template
    );
  }
  /**
   * inicia la conversacion con un cliente nuevo
   */
  async startConversation() {
    try {
      let channel = '';

      let node: any = {
        type: 'TEXT',
        text: [this.startConverstionObject.text],
      };

      switch (this.project.integrator) {
        case 'DROPI':
          channel = 'WHATSAPP_CLOUD';
          break;
        case 'MESSAGEBIRD':
          channel = 'WHATSAPP_MESSAGEBIRD';
          break;
        default:
          channel = 'WHATSAPP_CLOUD';
          break;
      }

      if (this.tempPhone.startsWith('0')) {
        this.tempPhone = this.tempPhone.substring(1);
      }

      this.startConverstionObject.phone = this.tempPhone
        .split(' ')
        .join('')
        .substring(1);

      /**
       * primero busco si ya existe una conversation para el phone number y chanel
       * que esten en status activa o trnasferida, con un agente diferente al actual
       * si no encuentra nada entonces continua, sino, bota unerror diciendo que ya hay un agente dueñiol de esa conversacion
       */
      Axios(`${BASE_URL_ORCHESTATOR}/conversation/get/byconsumerandchannel`, {
        params: {
          project: this.project._id,
          currentChannel: channel,
          consumer: [this.startConverstionObject.phone],
          currentConsumer: this.startConverstionObject.phone,
        },
      })
        .then(async (res: any) => {
          let { data } = res;
          let conversation = data.conversation;

          //cuando una conversacion es activa o ha sido trnasferida a un agente, se debe validar si ya etsa en una conevrsacion con otro agente para no permitir tomarla
          if (
            conversation != null &&
            conversation.currentAgent &&
            conversation.currentAgent !== this.$store.state.agent.agent._id &&
            ['ACTIVE', 'TRANSFERRED'].includes(conversation.status)
          ) {
            this.$notification.error({
              placement: 'bottomRight',
              message: `Error`,
              description: `El mensaje no se puede enviar porque el número de destino ya se encuentra en una una conversación activa con otro agente`,
            });
          } else {
            let respMessage = await Axios.post(
              `${BASE_URL_WHATSAPP_CLOUD}/user/${this.startConverstionObject.phone}`,
              {
                message: node,
                idmessase: null,
                isForwarded: false,
                idreference: null,
                apiKey: this.project.accessToken,
                phoneNumberId: this.project.phone_number_id,
                wabaId: this.project.bussinnes_account_id,
                template: this.startConverstionObject.template,
              },
            );

            let dataMessage = respMessage.data!.data;

            if (dataMessage.error) {
              this.$notification.error({
                placement: 'bottomRight',
                message: `Error`,
                description: `${dataMessage.error.error_data.details}`,
              });
            }
            const currentAgent = this.$store.state.agent.agent._id;

            let data = {
              nodo: node,
              conversation: {
                project: this.project._id,
                currentChannel: channel,
                consumer: [this.startConverstionObject.phone],
                currentConsumer: this.startConverstionObject.phone,
                topics: this.$store.state.agent.agent.subscriptions,
                show_in_queue: true,
                isTemplate: true,
                agent: currentAgent,
              },
              msgidentity: {
                idmessage: dataMessage!.resp!.data!.messages[0]!.id,
              },
              consumerName: this.startConverstionObject.name,
            };

            let url = `${BASE_URL_ORCHESTATOR}/conversation/interaction/output`;

            let respConversation = await Axios.post(url, data);

            let conversation = respConversation.data!.conversation;

            conversation.currentConsumer = this.startConverstionObject.phone;

            if (respConversation.status == 200) {
              store.commit(
                conversationMutationsTypes.setCurrentConversation(conversation),
              );
              this.onClear();

              this.grebConversation(conversation);

              Axios(
                `${BASE_URL_ORCHESTATOR}/agent/${
                  this.$store.state.agent.agent._id
                }/conversation/grab/${conversation!._id}`,
              )
                .then(res => {
                  let modal: any = $('#start-conversation');
                  modal.modal('hide');
                  this.startConverstionObject = {};
                })
                .catch(e => {
                  this.$notification.error({
                    placement: 'bottomRight',
                    message: `Error al tomar la conversacion`,
                    description: `Error: ${e!.message}`,
                  });
                })
                .finally(() => {
                  let modal: any = $('#start-conversation');
                  modal.modal('hide');
                  this.startConverstionObject = {};
                });
            } else {
              this.$notification.error({
                placement: 'bottomRight',
                message: `Hubo un error al guardar al información ${respConversation.status}`,
                description: `Error: ${respConversation.status}`,
              });
            }
          }
        })
        .catch(e => {
          this.$notification.error({
            placement: 'bottomRight',
            message: `Error al tomar la conversacion`,
            description: `No se pudo tomar la conversación`,
          });
        });
    } catch (e) {
      this.$notification.error({
        placement: 'bottomRight',
        description: `Hubo un error al guardar al información`,
        message: `Error:`,
      });
    }
  }

  async getTemplates() {
    const url = `${BASE_URL_MANAGER}/templates`;
    const response = await Axios.get(url, {
      params: {
        project_id: this.project._id,
      },
    });
    if (response.status == 200) {
      this.templates = response
        .data!.templates.map((t: any) => ({
          ...t,
          dontHaveParams: t.components.filter(
            (e: any) =>
              e.type == 'BODY' &&
              e.text != undefined &&
              e.text.indexOf('{') < 0,
          ),
        }))
        .filter((t: any) => t.dontHaveParams.length > 0);
    } else {
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al guardar al información ${response.status}`,
        description: `Error: ${response.status}`,
      });
    }
    this.retrieveQuickResponses();
  }

  formatNameTemplate(template: any) {
    const rawName = template.name;
    const name = rawName
      .split('_')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    const language = this.formatLanguageTemplate(template);
    const status = template.status === 'APPROVED' ? 'Aprobado' : 'Pendiente';
    return `${name} (${language}) - ${status}`;
  }

  formatLanguageTemplate(template: any) {
    switch (template.language) {
      case 'es':
        return 'Español';
      case 'en':
        return 'Ingles';
      case 'pt':
        return 'Portugues';
      default:
        return 'Español';
    }
  }

  async onChangeTemplate(e: any) {
    let body = await this.startConverstionObject.template.components.find(
      (e: any) => e.type == 'BODY',
    );
    this.startConverstionObject.text = await body.text;
  }

  // copy text to clipboard
  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.$notification.success({
      placement: 'bottomRight',
      message: `Texto copiado`,
      description: `El texto se ha copiado al portapapeles`,
    });
  }

  toggleRightSidebar() {
    const el = this.$refs.rightSidebarRef as HTMLElement;
    if (el.classList.contains('hide-right-sidebar-tablet')) {
      el.classList.toggle('hide-right-sidebar');
    } else {
      el.classList.remove('hide-right-sidebar-tablet');
    }
  }

  // Force reload the DropiInfoManager component
  forceRenderDropiInfoManager() {
    this.dropiInfoManagerKey += 1;
  }

  handleIncidenceStatus(data: any) {
    this.dropiIncidenceStatus = {
      ...data,
    };
  }

  // Filtros
  // Obtener todos los filtros por agente
  async getFilters() {
    try {
      const currenteAgent = this.$store.state.agent.agent._id;
      const project = this.$store.getters.project;
      const currentView = this.$store.state.filters.currentView;

      const url = `${BASE_URL_ORCHESTATOR}/filters/views/project/${project._id}/agent/${currenteAgent}/view/${currentView}`;
      const resp = await Axios.get(url);
      if (resp.status == 200) {
        // separar los filtros por inbox
        this.$store.commit(filterMutationsTypes.setFilterAgent([]));
        this.filters_activas = [];
        this.filters_resueltas = [];
        this.filters_auto = [];

        resp.data.filtrosAgente.forEach((filter: any) => {
          const ele = {
            label: (filter.icon ? filter.icon : '') + ' ' + filter.name,
            value: filter._id,
            config: filter.config,
            name: filter.name,
          };

          // remove duplicates
          if (
            filter.inbox === 'encola' &&
            !this.filters_encola.find((f: any) => f.value === ele.value)
          ) {
            this.$store.commit(filterMutationsTypes.pushNewFilterAgent(ele));
          } else if (
            filter.inbox == 'activas' &&
            !this.filters_activas.find((f: any) => f.value === ele.value)
          ) {
            this.filters_activas.push(ele);
          } else if (
            filter.inbox == 'resueltas' &&
            !this.filters_resueltas.find((f: any) => f.value === ele.value)
          ) {
            this.filters_resueltas.push(ele);
          } else if (
            filter.inbox == 'auto' &&
            !this.filters_auto.find((f: any) => f.value === ele.value)
          ) {
            this.filters_auto.push(ele);
          }
        });
        resp.data.filtros.forEach((filter: any) => {
          const ele = {
            label: (filter.icon ? filter.icon : '') + ' ' + filter.name,
            value: filter._id,
            config: filter.config,
            name: filter.name,
          };

          if (
            !this.filters_encola_admin.some((f: any) => f.value === ele.value)
          ) {
            this.$store.commit(filterMutationsTypes.pushNewFIlterProject(ele));
          }
        });
      } else {
        this.$notification.error({
          placement: 'bottomRight',
          message: `Hubo un error al obtener los filtros`,
        });
      }
    } catch (e) {
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al obtener los filtros`,
      });
    }
  }

  get conversationFiltered(): any[] {
    return this.$store.state.agent.agentQueueFiltered;
  }
  get conversationFilteredActive(): any[] {
    return this.$store.state.agent.agentOpenConversationsFiltered;
  }
  get conversationFilteredResolved(): any[] {
    return this.$store.state.agent.agentCloseConversationsFiltered;
  }
  get conversationFilteredAuto(): any[] {
    return this.$store.state.agent.agentAutoConversationsFiltered;
  }
  // Obtener las conversacione por el filtro seleccionado
  async getConversationsByFilter(filter: string, cancelToken: CancelToken) {
    try {
      this.loadingFilters = false;
      const currenteAgent = this.$store.state.agent.agent._id;
      const url = `${BASE_URL_ORCHESTATOR}/filters/conversations/${currenteAgent}/v2`;
      const data = {
        project: this.project._id,
        agent: currenteAgent,
        filter: filter,
        token: this.project.dropiUsers,
        country: this.project.country,
        subscriptions: this.$store.state.agent.agent.subscriptions,
      };

      const resp = await Axios.post(url, data, { cancelToken });
      if (resp.status == 200) {
        const conversations = resp.data;

        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsQueue(
            conversations.result,
          ),
        );
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsQueueTotal(
            conversations.result.length,
          ),
        );
        // loadingFilters true
        this.loadingFilters = true;
      }
    } catch (e) {
      this.loadingFilters = true;
      const error = e as AxiosError;
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al obtener los filtros`,
        description: `Error: ${error.message}`,
      });
    }
  }

  // Obtener las conversacione por el filtro seleccionado
  async getConversationByFilterActive(
    filter: string,
    cancelToken: CancelToken,
  ) {
    try {
      this.loadingFiltersActive = false;
      const currenteAgent = this.$store.state.agent.agent._id;
      const url = `${BASE_URL_ORCHESTATOR}/filters/conversations/${currenteAgent}/v2`;
      const data = {
        project: this.project._id,
        agent: currenteAgent,
        filter: filter,
        token: this.project.dropiUsers,
        country: this.project.country,
      };
      const resp = await Axios.post(url, data, { cancelToken });
      if (resp.status == 200) {
        const conversations = resp.data;
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsOpen(
            conversations.result,
          ),
        );
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsOpenTotal(
            conversations.result.length,
          ),
        );
        this.loadingFiltersActive = true;
      }
    } catch (e) {
      this.loadingFiltersActive = true;
      const error = e as AxiosError;
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al obtener los filtros`,
        description: `Error: ${error.message}`,
      });
    }
  }

  async getConversationByFilterClosed(
    filter: string,
    cancelToken: CancelToken,
  ) {
    try {
      this.loadingFiltersResolved = false;
      const currenteAgent = this.$store.state.agent.agent._id;
      const url = `${BASE_URL_ORCHESTATOR}/filters/conversations/${currenteAgent}/v2`;
      const data = {
        project: this.project._id,
        agent: currenteAgent,
        filter: filter,
        token: this.project.dropiUsers,
        conversations: this.completeConversations,
        country: this.project.country,
      };
      const resp = await Axios.post(url, data, { cancelToken });
      if (resp.status == 200) {
        const conversations = resp.data;
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsClose(
            conversations.result,
          ),
        );
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsCloseTotal(
            conversations.result.length,
          ),
        );
        this.loadingFiltersResolved = true;
      }
    } catch (e) {
      this.loadingFiltersResolved = true;
      const error = e as AxiosError;
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al obtener los filtros`,
        description: `Error: ${error.message}`,
      });
    }
  }

  async getConversationByFilterAuto(filter: string, cancelToken: CancelToken) {
    try {
      this.loadingFiltersAuto = false;
      const currenteAgent = this.$store.state.agent.agent._id;
      const url = `${BASE_URL_ORCHESTATOR}/filters/conversations/${currenteAgent}/v2`;
      const data = {
        project: this.project._id,
        agent: currenteAgent,
        filter: filter,
        token: this.project.dropiUsers,
        country: this.project.country,
      };
      const resp = await Axios.post(url, data, { cancelToken });
      if (resp.status == 200) {
        const conversations = resp.data;
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsAuto(
            conversations.result,
          ),
        );
        store.commit(
          storeTypes.agent.mutations.setFilteredConversationsAutoTotal(
            conversations.result.length,
          ),
        );
        this.loadingFiltersAuto = true;
      }
    } catch (e) {
      this.loadingFiltersAuto = true;
      const error = e as AxiosError;
      this.$notification.error({
        placement: 'bottomRight',
        message: `Hubo un error al obtener los filtros`,
        description: `Error: ${error.message}`,
      });
    }
  }

  handleChangeQueueFilter(filterP: string) {
    if (filterP === 'crear_filtro' || filterP === 'd') {
      return;
    }

    if (this.topicFilterValue !== 'crear_filtro') {
      this.previousTopicFilterValue = this.topicFilterValue;
    }

    if (this.filters_encola.some((filter: any) => filter.value === filterP)) {
      const filter = this.filters_encola.find(
        (filter: any) => filter.value === filterP,
      ) as any;
      store.commit(storeTypes.agent.mutations.filterSelectedQueue(filter));
      if (this.searchTimer) clearTimeout(this.searchTimer);
      this.source.cancel('Operation canceled by the user.');
      this.source = Axios.CancelToken.source();
      this.searchTimer = setTimeout(() => {
        this.getConversationsByFilter(this.topicFilterValue, this.source.token);
      }, 500); // return conversation;
    } else {
      store.commit(
        storeTypes.agent.mutations.filterSelectedQueue(this.filterDefault),
      );
    }
  }
  handleChangeActiveFilter(filterP: string) {
    if (this.activeBusinessStatusFilterValue !== 'crear_filtro') {
      this.previousActiveBusinessStatusFilterValue =
        this.activeBusinessStatusFilterValue;
    }
    if (this.filters_activas.some((filter: any) => filter.value === filterP)) {
      // search in filters if filterP is in filters array
      const filter = this.filters_activas.find(
        (filter: any) => filter.value === filterP,
      );
      store.commit(storeTypes.agent.mutations.filterSelectedOpen(filter));
      this.getConversationByFilterActive(
        this.activeBusinessStatusFilterValue,
        this.source.token,
      );
      // return conversation;
    }
  }

  handleChangeCloseFilter(filterP: string) {
    if (this.resolvedBusinessStatusFilterValue !== 'crear_filtro') {
      this.previousResolvedBusinessStatusFilterValue =
        this.resolvedBusinessStatusFilterValue;
    }

    if (
      this.filters_resueltas.some((filter: any) => filter.value === filterP)
    ) {
      const filter = this.filters_resueltas.find(
        (filter: any) => filter.value === filterP,
      );
      store.commit(storeTypes.agent.mutations.filterSelectedClose(filter));
      this.getConversationByFilterClosed(
        this.resolvedBusinessStatusFilterValue,
        this.source.token,
      );

      // return conversation;
    } else {
      store.commit(
        storeTypes.agent.mutations.filterSelectedClose(this.filterDefault),
      );
    }
  }
  handleChangeAutoFilter(filterP: string) {
    if (this.autoBusinessStatusFilterValue !== 'crear_filtro') {
      this.previousAutoBusinessStatusFilterValue =
        this.autoBusinessStatusFilterValue;
    }
    if (this.filters_auto.some((filter: any) => filter.value === filterP)) {
      const filter = this.filters_auto.find(
        (filter: any) => filter.value === filterP,
      );
      store.commit(storeTypes.agent.mutations.filterSelectedAuto(filter));
      this.getConversationByFilterAuto(
        this.autoBusinessStatusFilterValue,
        this.source.token,
      );
      // return conversation;
    } else {
      store.commit(
        storeTypes.agent.mutations.filterSelectedAuto(this.filterDefault),
      );
    }
  }

  // count total auto
  get totalAuto(): number {
    if (
      store.state.agent!.filterSelectedAuto.value !== 'all' &&
      this.currentTab === 'auto'
    ) {
      return store.state.agent!.agentAutoTotalConversationsFiltered || 0;
    } else {
      return store.state.agent!.agentAutoTotalConversations || 0;
    }
  }

  get project(): IProject {
    return this.$store.getters.project;
  }

  enableShowCheckboxQueue() {
    this.showCheckboxQueue = true;
  }

  disableShowCheckboxQueue() {
    this.showCheckboxQueue = false;
    this.filteredAgentQueueConversations.forEach(conversation => {
      conversation.checked = false;
    });
    this.conversationSelectedQueue = 0;
    this.showActionsMenu = false;
  }

  onSelectAllQueue() {
    this.filteredAgentQueueConversations.forEach(conversation => {
      conversation.checked = true;
    });
    this.conversationSelectedQueue = store.state.agent!.agentQueueTotal;
    this.isSelectedAll = true;
  }

  enableShowCheckboxOpen() {
    this.showCheckboxOpen = true;
  }

  disableShowCheckboxOpen() {
    this.showCheckboxOpen = false;
    this.arrayActiveConversationFilter.forEach(conversation => {
      conversation.checked = false;
    });
    this.conversationSelectedOpen = 0;
    this.showActionsMenu = false;
  }

  onSelectAllOpen() {
    this.arrayActiveConversationFilter.forEach(conversation => {
      conversation.checked = true;
    });
    this.conversationSelectedOpen =
      store.state.agent!.agentOpenTotalConversations;
    this.isSelectedAll = true;
  }

  enableShowCheckboxAuto() {
    this.showCheckboxAuto = true;
  }

  disableShowCheckboxAuto() {
    this.showCheckboxAuto = false;
    this.filteredAutoConversation.forEach(conversation => {
      conversation.checked = false;
    });
    this.conversationSelectedAuto = 0;
    this.showActionsMenu = false;
  }

  onSelectAllAuto() {
    this.filteredAutoConversation.forEach(conversation => {
      conversation.checked = true;
    });
    this.conversationSelectedAuto =
      store.state.agent!.agentAutoTotalConversations;

    this.isSelectedAll = true;
  }

  conversationSelectedModal() {
    if (this.currentTab === 'encola') {
      return this.conversationSelectedQueue;
    } else if (this.currentTab === 'activas') {
      return this.conversationSelectedOpen;
    } else if (this.currentTab === 'auto') {
      return this.conversationSelectedAuto;
    }
  }

  async transferToClose() {
    this.loadingTrasfering = true;
    const actionMap: { [key: string]: any } = {
      encola: {
        all: storeTypes.conversation.actions.closeAllConversationQueue,
        selected: storeTypes.conversation.actions.closeSelectedConversation,
        selectedParam: this.convSelectedQueue,
        loadConversation: storeTypes.agent.actions.loadAgentQueues({
          subscriptions: store.state.agent!.agent.subscriptions,
          page: store.state.agent!.actualPage,
          restartQueue: true,
        }),
        checkbox: this.disableShowCheckboxQueue,
      },
      activas: {
        all: storeTypes.conversation.actions.closeAllConversationOpen,
        selected: storeTypes.conversation.actions.closeSelectedConversation,
        selectedParam: this.convSelectedOpen,
        loadConversation: storeTypes.agent.actions.loadAgentOpenConversations({
          agent_id: store.state.agent!.agent._id,
          page: 0,
          limit: 50,
        }),
        checkbox: this.disableShowCheckboxOpen,
      },
      auto: {
        all: storeTypes.conversation.actions.closeAllConversationAuto,
        selected: storeTypes.conversation.actions.closeSelectedConversation,
        selectedParam: this.convSelectedAuto,
        loadConversation: storeTypes.agent.actions.loadAgentAutoConversations({
          subscriptions: store.state.agent!.agent.subscriptions,
          page: 0,
        }),
        checkbox: this.disableShowCheckboxAuto,
      },
    };

    const currentAction = actionMap[this.currentTab];

    if (currentAction) {
      if (this.isSelectedAll) {
        await store.dispatch(currentAction.all());
      } else {
        await store.dispatch(
          currentAction.selected(currentAction.selectedParam),
        );
      }
      await store.dispatch(currentAction.loadConversation);
      await store.dispatch(
        storeTypes.agent.actions.loadAgentCloseConversations({
          agent_id: store.state.agent!.agent._id,
          page: 0,
        }),
      );
      currentAction.checkbox();
    }

    if (this.currentTab === 'activas' && this.isSelectedAll) {
      await store.dispatch(storeTypes.agent.actions.resetOpenConversations());
    }
    this.showTransferClose = false;
    this.loadingTrasfering = false;
    this.isSelectedAll = false;
    // notificacion
    this.$notification.success({
      placement: 'bottomRight',
      message: 'Conversación cerrada',
      description: 'Las conversaciones han sido cerradas',
    });
  }

  // IRIS
}
