import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import {
  Conversation,
  ConversationNewEntryQueue,
} from '@/app/store/modules/conversation/conversation.models';
import { store, storeTypes } from '@/app/store';
import moment from 'moment';
import Axios, { CancelToken, CancelTokenSource } from 'axios';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

@Component({
  name: 'search-conversation-widget',
  components: { PerfectScrollbar },
})
export default class SearchConversationWidget extends VueWizard {
  @Prop() public readonly openConversations!: any[];
  @Prop() public readonly closeConversations!: any[];
  @Prop() public readonly queueConversations!: any[];
  @Prop() public readonly agentTopics!: any;

  public searchKeyWord = '';
  public topicSearchFilterValue: any = null;
  public searchResults: Conversation[] = [];
  public loading = false;
  public openResultsQuantity = 0;
  public closeResultsQuantity = 0;
  public queueResultsQuantity = 0;
  public openResultConversation: Conversation[] = [];
  public closeResultConversation: Conversation[] = [];
  public queueResultConversation: Conversation[] = [];
  public autoResultConversation: Conversation[] = [];
  public totalConversationQuantity = 0;
  private searchTimer: any;
  private searchDelay: number = 500;
  private source: CancelTokenSource = Axios.CancelToken.source();

  // save previos state of tab
  private previosState: string = '';

  getUserPicture(conversation: Conversation): string {
    return conversation.variables.CONSUMER_PROFILE_PICTURE.value || '';
  }
  getInitials(conversation: Conversation): string {
    let userName = 'C';
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME &&
      conversation.variables.CONSUMER_NAME.value != undefined &&
      isNaN(conversation.variables.CONSUMER_NAME.value)
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
      if (userName != undefined && userName != '' && userName != null) {
        userName = userName.charAt(0);
      }
    }
    return userName;
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }

  getLastText(item: Conversation): string {
    let lastText = '';
    if (item.conversationJson) {
      lastText = item.conversationJson.text!.body;
    }
    return lastText;
  }

  formatUpdatedDate(date: Date | string): string {
    moment.locale('es');
    return moment(date).format('DD/MM/YYYY');
  }
  getNameAgent(item: any): string {
    let name = 'Sin agente';
    if (item.agent != undefined) {
      name =
        item.agent.user[0].name.firstName +
        ' ' +
        item.agent.user[0].name.lastName;
    }
    return name;
  }
  getUserName(item: any): any {
    let userName = '';
    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }
    if (
      conversation != undefined &&
      conversation.variables != undefined &&
      conversation.variables.CONSUMER_NAME
    ) {
      userName = conversation.variables.CONSUMER_NAME.value;
    }
    return userName;
  }

  get currentConversation(): any {
    return store.state.conversation!.currentConversation;
  }

  cancelSearch(): void {
    this.searchKeyWord = '';
    this.searchResults = [];
    this.openResultConversation = [];
    this.closeResultConversation = [];
    this.queueResultConversation = [];
    this.autoResultConversation = [];
    this.totalConversationQuantity = 0;
  }
  @Watch('searchKeyWord')
  emptySearch(): void {
    if (this.searchKeyWord.length == 0) {
      this.cancelSearch();

      if (this.previosState !== store.state.agent!.tabSelected) {
        store.commit(
          storeTypes.agent.mutations.setTabSelected(this.previosState),
        );
      } else {
      }
    }
    if (this.searchKeyWord.length == 2) {
      this.previosState = store.state.agent!.tabSelected;
      store.commit(storeTypes.agent.mutations.setTabSelected('search'));
    }
  }

  onSelect(conversation: Conversation): void {
    this.$emit('selectConversation', conversation);
  }
  async onSearchChange(): Promise<void> {
    if (this.searchTimer) clearTimeout(this.searchTimer);
    this.source.cancel('Operation canceled by the user.');
    this.source = Axios.CancelToken.source();
    this.searchResults = [];
    this.openResultConversation = [];
    this.closeResultConversation = [];
    this.queueResultConversation = [];
    this.autoResultConversation = [];
    this.totalConversationQuantity = 0;
    this.searchTimer = setTimeout(async () => {
      try {
        await this.search();
      } catch (error) {
        if (Axios.isCancel(error)) {
          console.log('Request canceled');
        } else {
          console.log(error);
        }
      }
    }, this.searchDelay);
  }

  async search(): Promise<void> {
    if (this.searchKeyWord.length >= 2) {
      this.loading = true;

      this.searchResults = [];
      await this.obtainSearchResults();
    }
  }

  async getfilteredOpenConversations(cancelToken: CancelToken): Promise<any> {
    const project = store.state.user!.user.company!.projects[0];
    let url = `${BASE_URL_ORCHESTATOR}/agent/${
      store.state.agent!.agent._id
    }/conversation/open`;
    let res = await Axios.get(url, {
      params: {
        limit: 10,
        page: 0,
        keyword: this.searchKeyWord,
        isSearch: true,
        project: project._id,
      },
      cancelToken,
    });
    return res.data.conversations.map((conversation: any) =>
      Object.assign(conversation, { state: 'open' }),
    );
  }
  async getFilteredCloseConversations(cancelToken: CancelToken): Promise<any> {
    const project = store.state.user!.user.company!.projects[0];

    let url = `${BASE_URL_ORCHESTATOR}/agent/${
      store.state.agent!.agent._id
    }/conversation/close`;
    let res = await Axios.get(url, {
      params: {
        limit: 10,
        page: 0,
        keyword: this.searchKeyWord,
        isSearch: true,
        project: project._id,
      },
      cancelToken,
    });

    return res.data.conversations.map((conversation: any) =>
      Object.assign(conversation, { state: 'close' }),
    );
  }

  async getFilteredQueueConversations(cancelToken: CancelToken): Promise<any> {
    let url = `${BASE_URL_MANAGER}/queue`;
    let res = await Axios.get(url, {
      params: {
        subscriptions: store.state.agent!.agent.subscriptions,
        limit: 10,
        page: 0,
        keyword: this.searchKeyWord,
      },
      cancelToken,
    });

    return res.data.queues.map((conversation: any) =>
      Object.assign(conversation, { state: 'queue' }),
    );
  }

  async getFilteredAutoConversations(cancelToken: CancelToken): Promise<any> {
    const project = store.state.user!.user.company!.projects[0];

    let url = `${BASE_URL_ORCHESTATOR}/agent/${project._id}/conversation/v2/auto`;
    let res = await Axios.get(url, {
      params: {
        limit: 10,
        page: 0,
        keyword: this.searchKeyWord,
      },
      cancelToken,
    });

    return res.data.conversations.map((conversation: any) =>
      Object.assign(conversation, { state: 'auto' }),
    );
  }

  @Watch('topicSearchFilterValue')
  async obtainSearchResults(): Promise<any> {
    try {
      const result = await Promise.all([
        this.getfilteredOpenConversations(this.source.token),
        this.getFilteredCloseConversations(this.source.token),
        this.getFilteredQueueConversations(this.source.token),
        this.getFilteredAutoConversations(this.source.token),
      ]);
      this.openResultConversation = result[0];
      this.closeResultConversation = result[1];
      this.queueResultConversation = result[2];
      this.autoResultConversation = result[3];

      this.totalConversationQuantity =
        this.openResultConversation.length +
        this.closeResultConversation.length +
        this.queueResultConversation.length +
        this.autoResultConversation.length;
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log('Request canceled');
      } else {
        console.log(error);
      }
    } finally {
      this.$set(this, 'loading', false);
    }

    if (this.topicSearchFilterValue) {
      this.searchResults = this.searchResults.filter(
        (conversation: any) =>
          this.getTopic(conversation) ===
          this.getFilterTopic(this.topicSearchFilterValue),
      );
    }
  }

  getTopic(item: ConversationNewEntryQueue): string {
    return this.cleanTopic(
      item.topic ? item.topic : item.currentTopic ? item.currentTopic : '',
    );
  }

  getFilterTopic(item: string): string {
    return this.cleanTopic(item);
  }

  cleanTopic(topic: string): string {
    let topicElements: any[] = [];
    if (topic != undefined && topic.includes('_')) {
      topicElements = topic.split('_');
      topic = '';
      for (let i = 2; i < topicElements.length; i++) {
        topic = topic + topicElements[i] + ' ';
      }
      topic.trim();
    }
    return topic;
  }
}
